import React, {useEffect, useRef, useState} from 'react';
import {
  addAdverseEventToPatient,
  addFollowUpToPatient,
  deletePatient,
  getPatients, updateAdverseEvent,
  updateCircumcision, updateFollowUpCheck,
  updatePatient
} from "../../utils/api";

import { withStyles, makeStyles } from '@mui/styles';
import {
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Alert,
  Checkbox,
  Snackbar,
  Radio,
  FormControlLabel,
  RadioGroup,
  Grid,
  ImageList,
  Toolbar,
  AppBar,
  Typography,
  Collapse,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  CircularProgress,
  TableCell,
  TableContainer,
  TextField,
  Paper,
} from "@mui/material";
import {formatTime} from "../../utils/timeHelper";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../layouts/Description.parts";
import EditableInput from "../input/EditableInput";
import PropTypes from "prop-types";
import {isNumeric} from "../../utils/numbersHelper";
import AddFollowUpDialog from "../dialogs/AddFollowUpDialog";
import AddAdverseEventDialog from "../dialogs/AddAdverseEventDialog";
import ConfirmDischargeDialog from "../dialogs/ConfirmDischargeDialog";
import PrintFollowUpListDialog from "../dialogs/PrintFollowUpListDialog";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
  contentWrapper: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgba(27, 66, 136, 1)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function Row(props) {
  let { patient, fetchData, removeHandler } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const cancelRequest = useRef();
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [patientBuffer, setPatientBuffer] = useState(patient);

  const insertStandardNotes = () => {
    updateCircumcisionDataAndDispatch(
        patient.id,
        ['notes'],
        ['Under aseptic conditions, circumcision was performed according to the training\n'
        + 'received and the Unicirc Protocol (IFU Instructions for use). No intra-operative\n'
        + 'nor post-operative complications were experienced. The patient/guardian was\n'
        + 'informed of post-operative care and an information sheet given in this regard.\n'
        + 'Review, if no issues or concerns prior, was scheduled for 2/7/28 days.']);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackbarOpen(false);
  };

  const dischargeConfirmationHandler =
      async (patientId) => {
        await updatePatientDataAndDispatch(patientId,
            ['discharged'],
            [true]);
        await fetchData();
      };

  const addFollowUpDataAndDispatch = async (patientId, fields, values) => {
    try {
      let pBuffer = {
        ...patientBuffer
      };

      let updatedFields = {};

      for (let i = 0; i < fields.length; i++) {
        pBuffer[fields[i]] = values[i];
        updatedFields[fields[i]] = values[i];
      }

      setPatientBuffer(pBuffer);

      const { data: patientData } = await addFollowUpToPatient(
          patientId,
          updatedFields
      );

      if (!cancelRequest.current) {
        setPatientBuffer(patientData);
      }
    } catch (error) {
      setPatientBuffer(patientBuffer);
    }
  };

  const addAdverseEventDataAndDispatch = async (patientId, fields, values) => {
    try {
      let pBuffer = {
        ...patientBuffer
      };

      let updatedFields = {};

      for (let i = 0; i < fields.length; i++) {
        pBuffer[fields[i]] = values[i];
        updatedFields[fields[i]] = values[i];
      }

      setPatientBuffer(pBuffer);

      const { data: patientData } = await addAdverseEventToPatient(
          patientId,
          updatedFields
      );

      if (!cancelRequest.current) {
        setPatientBuffer(patientData);
      }
    } catch (error) {
      setPatientBuffer(patientBuffer);
    }
  };

  const updateFollowUpDataAndDispatch =
      async (patientId, followUpCheckId, fields, values) => {
    try {
      let pBuffer = {
        ...patientBuffer
      };

      let updatedFields = {};

      for (let i = 0; i < fields.length; i++) {
        pBuffer[fields[i]] = values[i];
        updatedFields[fields[i]] = values[i];
      }

      setPatientBuffer(pBuffer);

      const { data: patientData } = await updateFollowUpCheck(
          patientId,
          followUpCheckId,
          updatedFields
      );

      if (!cancelRequest.current) {
        setPatientBuffer(patientData);
      }
    } catch (error) {
      setPatientBuffer(patientBuffer);
    }
  };

  const updateAdverseEventDataAndDispatch =
      async (patientId, adverseEventId, fields, values) => {
        try {
          let pBuffer = {
            ...patientBuffer
          };

          let updatedFields = {};

          for (let i = 0; i < fields.length; i++) {
            pBuffer[fields[i]] = values[i];
            updatedFields[fields[i]] = values[i];
          }

          setPatientBuffer(pBuffer);

          const { data: patientData } = await updateAdverseEvent(
              patientId,
              adverseEventId,
              updatedFields
          );

          if (!cancelRequest.current) {
            setPatientBuffer(patientData);
          }
        } catch (error) {
          setPatientBuffer(patientBuffer);
        }
      };

  const updatePatientDataAndDispatch = async (patientId, fields, values) => {
    try {
      let pBuffer = {
        ...patientBuffer
      };

      let updatedFields = {};

      for (let i = 0; i < fields.length; i++) {
        pBuffer[fields[i]] = values[i];
        updatedFields[fields[i]] = values[i];
      }

      setPatientBuffer(pBuffer);

      const { data: patientData } = await updatePatient(
          patientId,
          updatedFields
      );

      if (!cancelRequest.current) {
        setPatientBuffer(patientData);
      }
    } catch (error) {
      setPatientBuffer(patientBuffer);
    }
  };

  const updateCircumcisionDataAndDispatch = async (patientId, fields, values) => {
    try {
      let pBuffer = {
        ...patientBuffer
      };

      let updatedFields = {};

      for (let i = 0; i < fields.length; i++) {
        pBuffer[fields[i]] = values[i];
        updatedFields[fields[i]] = values[i];
      }

      setPatientBuffer(pBuffer);

      const { data: patientData } = await updateCircumcision(
          patientId,
          updatedFields
      );

      if (!cancelRequest.current) {
        setPatientBuffer(patientData);
      }
    } catch (error) {
      setPatientBuffer(patientBuffer);
    }
  };

  const ageString = `${patientBuffer.age !== null ? patientBuffer.age : ''} ${patientBuffer.months !== null && patientBuffer.age < 1 ? ' (' + patientBuffer.months + ' ' + (patientBuffer.months === 1 ? 'month' : 'months') + ')' : ''}`;

  return (
      <React.Fragment>
        <TableRow className={classes.root} style={patientBuffer.problemPatient ? {backgroundColor: '#ffcccb'} : {backgroundColor: '#ffffff'}}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{patientBuffer.firstName}</TableCell>
          <TableCell>{patientBuffer.lastName}</TableCell>
          <TableCell>{ageString}</TableCell>
          <TableCell>{formatTime(patientBuffer.circumcisionDate)}</TableCell>
          <TableCell>{patient.daysSinceCircumcision ? patient.daysSinceCircumcision : ''}</TableCell>
          <TableCell>{patient.daysSinceLastFollowUp ? patient.daysSinceLastFollowUp : ''}</TableCell>
          <TableCell>
            <ConfirmDischargeDialog confirmationHandler={dischargeConfirmationHandler} patient={patient} />
            <ConfirmActionDialog action={'delete'} confirmationHandler={() => removeHandler(patient.id)}/>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
              <Typography variant="h5" gutterBottom component="div">
                Patient details
              </Typography>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      General information
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>File number</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="fileNumber"
                            value={patientBuffer.fileNumber}
                            disabled={true}
                            fullWidth={true}
                            size={'large'}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                  <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>First name</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="firstName"
                            value={patientBuffer.firstName}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Last name</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="lastName"
                            value={patientBuffer.lastName}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Date of birth</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="dateOfBirth"
                            type="date"
                            value={formatTime(patientBuffer.dateOfBirth, 'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Age</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="age"
                            value={ageString}
                            disabled={true}
                            fullWidth={true}
                            onSave={() => {}}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Phone number #1</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="phoneNumber"
                            type="text"
                            value={patientBuffer.phoneNumber}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Phone number #2</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="mobileNumber"
                            type="text"
                            value={patientBuffer.mobileNumber}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Email</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="email"
                            type="text"
                            value={patientBuffer.email}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Place of residence</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="placeOfResidence"
                            type="text"
                            value={patientBuffer.placeOfResidence}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Reason for circumcision</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="reasonCircumcision"
                            type="text"
                            value={patientBuffer.reasonCircumcision}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item xs={8}>Problem patient?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="problemPatient" name="problemPatient" defaultValue={patientBuffer.problemPatient ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['problemPatient'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item xs={6}>
                      <DescriptionList>
                        <DescriptionTerm>Reason</DescriptionTerm>
                        <Description>
                          <EditableInput
                              name="problemPatientNotes"
                              value={patientBuffer.problemPatientNotes}
                              disabled={false}
                              fullWidth={true}
                              size={'large'}
                              rows={4}
                              multiline={true}
                              onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                          />
                        </Description>
                      </DescriptionList>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Appointment info
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Date of circumcision</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="circumcisionDate"
                            type="datetime-local"
                            value={formatTime(patientBuffer.circumcisionDate, 'dd.LL.yyyy HH:mm')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value.replace('T', ' ').concat(':00.000')])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Medical aid</DescriptionTerm>
                      <Description>
                        <Checkbox
                            checked={patientBuffer.medicalAid}
                            onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['medicalAid'], [event.target.checked])}}
                            name="medicalAid"
                            inputProps={{ 'aria-label': 'medical aid checkbox' }}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Pre-check data
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item xs={8}>Generally healthy?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="generallyHealthy" name="generallyHealthy" defaultValue={patientBuffer.generallyHealthy ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['generallyHealthy'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Significant illness?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="significantIllness" name="significantIllness" defaultValue={patientBuffer.significantIllness ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['significantIllness'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Experiencing urethral discharge?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="urethralDischarge" name="urethralDischarge" defaultValue={patientBuffer.urethralDischarge ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['urethralDischarge'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Currently taking aspirin?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="takingAspirin" name="takingAspirin" defaultValue={patientBuffer.takingAspirin ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['takingAspirin'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Past reaction to local anesthetic?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="pastReactionToLocalAnesthetic" name="pastReactionToLocalAnesthetic" defaultValue={patientBuffer.pastReactionToLocalAnesthetic ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['pastReactionToLocalAnesthetic'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Known bleeding disorder or tends to bleed profusely when cut?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="bleedingDisorder" name="bleedingDisorder" defaultValue={patientBuffer.bleedingDisorder ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['bleedingDisorder'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Genital sore (ulcer)?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="genitalSore" name="genitalSore" defaultValue={patientBuffer.genitalSore ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['genitalSore'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Pain on erection?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="painOnErection" name="painOnErection" defaultValue={patientBuffer.painOnErection ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['painOnErection'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Swelling of the scrotum?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="swellingScrotum" name="swellingScrotum" defaultValue={patientBuffer.swellingScrotum ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['swellingScrotum'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Pain on urination?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="painOnUrination" name="painOnUrination" defaultValue={patientBuffer.painOnUrination ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['painOnUrination'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Difficulty in retracting foreskin?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="difficultyRetractingForeskin" name="difficultyRetractingForeskin" defaultValue={patientBuffer.difficultyRetractingForeskin ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['difficultyRetractingForeskin'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Hypertension?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="hypertension" name="hypertension" defaultValue={patientBuffer.hypertension ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['hypertension'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Fever / Chills?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="feverChills" name="feverChills" defaultValue={patientBuffer.feverChills ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['feverChills'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Shortness of Breath?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="shortnessOfBreath" name="shortnessOfBreath" defaultValue={patientBuffer.shortnessOfBreath ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['shortnessOfBreath'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Abdominal pain?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="abdominalPain" name="abdominalPain" defaultValue={patientBuffer.abdominalPain ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['abdominalPain'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Open cuts or bruises?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="bruises" name="bruises" defaultValue={patientBuffer.bruises ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['bruises'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Rash?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="rash" name="rash" defaultValue={patientBuffer.rash ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['rash'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Diabetes?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="diabetes" name="diabetes" defaultValue={patientBuffer.diabetes ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['diabetes'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Any medication allergies?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="medicationAllergies" name="medicationAllergies" defaultValue={patientBuffer.medicationAllergies ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['medicationAllergies'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Treated for any sexually transmitted infections in past month?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="treatedForStd" name="treatedForStd" defaultValue={patientBuffer.treatedForStd ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['treatedForStd'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Abnormalities on foreskin?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="abnormalForeskin" name="abnormalForeskin" defaultValue={patientBuffer.abnormalForeskin ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['abnormalForeskin'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <br/>
                  <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Blood pressure - Systolic (mmHg)</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="bloodPressureTop"
                            value={patientBuffer.bloodPressureTop}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Blood pressure - Diastolic (mmHg)</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="bloodPressureBottom"
                            value={patientBuffer.bloodPressureBottom}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Weight (kg)</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="weight"
                            value={patientBuffer.weight}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Blood Sugar Level (mmol/L)</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="bloodSugarLevel"
                            value={patientBuffer.bloodSugarLevel}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Temperature (°C)</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="temperature"
                            value={patientBuffer.temperature}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>SpO2 (%) - Oxygen saturation</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="oxygenSaturation"
                            value={patientBuffer.oxygenSaturation}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Pulse (b/min)</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="pulse"
                            value={patientBuffer.pulse}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Final pre-circ examination data
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item xs={8}>Epispadius?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="epispadius" name="epispadius" defaultValue={patientBuffer.epispadius ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['epispadius'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={8}>Hypospadius?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="hypospadius" name="hypospadius" defaultValue={patientBuffer.hypospadius ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['hypospadius'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={8}>Tight frenulum?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="tightFrenulum" name="tightFrenulum" defaultValue={patientBuffer.tightFrenulum ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['tightFrenulum'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={8}>Phimosis?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="phimosis" name="phimosis" defaultValue={patientBuffer.phimosis ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['phimosis'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={8}>Burried penis?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="burriedPenis" name="burriedPenis" defaultValue={patientBuffer.burriedPenis ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['burriedPenis'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={8}>Web penis?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="webPenis" name="webPenis" defaultValue={patientBuffer.webPenis ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['webPenis'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={8}>Chordee?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="chordee" name="chordee" defaultValue={patientBuffer.chordee ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['chordee'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={8}>STI?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="sti" name="sti" defaultValue={patientBuffer.sti ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['sti'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={8}>Web penis?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="warts" name="warts" defaultValue={patientBuffer.warts ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['warts'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <DescriptionList>
                      <DescriptionTerm>Any other abnormalities?</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="abnormalGenitalDescription"
                            value={patientBuffer.abnormalGenitalDescription}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            rows={4}
                            multiline={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                  </Grid>
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Circumcision data
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <Typography variant="h6" gutterBottom component="div">
                    Device information
                  </Typography>
                  <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <Description>
                        <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                          <DescriptionList>
                            <DescriptionTerm>Unicirc device size</DescriptionTerm>
                            <Description>
                              <FormControl size="small" variant="outlined" className={classes.formControl} style={{width: '100%', marginTop: '0.3rem'}}>
                                <Select
                                    labelId="device-type"
                                    id="device-type-select"
                                    value={patientBuffer.circumcision && patientBuffer.circumcision.deviceType ? patientBuffer.circumcision.deviceType : ''}
                                    onChange={(event) => updateCircumcisionDataAndDispatch(patient.id, ['deviceType'], [event.target.value])}
                                    className={classes.selectEmpty}
                                    fullWidth
                                >
                                  <MenuItem value={''} disabled> </MenuItem>
                                  <MenuItem value={'R'}>R</MenuItem>
                                  <MenuItem value={'Q'}>Q</MenuItem>
                                  <MenuItem value={'A'}>A</MenuItem>
                                  <MenuItem value={'B'}>B</MenuItem>
                                  <MenuItem value={'C'}>C</MenuItem>
                                  <MenuItem value={'D'}>D</MenuItem>
                                  <MenuItem value={'E'}>E</MenuItem>
                                  <MenuItem value={'F'}>F</MenuItem>
                                </Select>
                              </FormControl>
                            </Description>
                          </DescriptionList>
                          <DescriptionList>
                              <DescriptionTerm>Device ID (e.g. 100)</DescriptionTerm>
                              <Description>
                                <EditableInput
                                    name="device"
                                    value={patientBuffer.circumcision ? patientBuffer.circumcision.device : null}
                                    disabled={false}
                                    fullWidth={true}
                                    size={'large'}
                                    onSave={(fieldName, value) => updateCircumcisionDataAndDispatch(patient.id, [fieldName], [value])}
                                />
                              </Description>
                          </DescriptionList>
                        </ImageList>
                      </Description>
                    </DescriptionList>
                  </ImageList>
                  <Typography variant="h6" gutterBottom component="div">
                    Anaesthetic
                  </Typography>
                  <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                    <Grid component="label" container alignItems="center" spacing={1} >
                      <Grid item xs={8}>EMLA 5g</Grid>
                      <Grid item xs={4}>
                        <RadioGroup row aria-label="anaestheticEmla" name="anaestheticEmla" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticEmla ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['anaestheticEmla'], [event.target.value === 'true'])}}>
                          <FormControlLabel
                              value="false"
                              control={<Radio color="primary" />}
                              label="No"
                          />
                          <FormControlLabel
                              value="true"
                              control={<Radio color="primary" />}
                              label="Yes"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    { patientBuffer.circumcision && patientBuffer.circumcision.anaestheticEmla &&
                    <Grid>
                      <DescriptionList>
                        <Description>
                          <FormControl size="small" variant="outlined" className={classes.formControl} style={{width: '20%', marginTop: 3}}>
                            <InputLabel id="anaesthetic-emla-amount">Dose</InputLabel>
                            <Select
                                labelId="anaesthetic-emla-amount"
                                id="anaesthetic-emla-amount-select"
                                value={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticEmlaAmount ? patientBuffer.circumcision.anaestheticEmlaAmount : ''}
                                onChange={(event) => updateCircumcisionDataAndDispatch(patient.id, ['anaestheticEmlaAmount'], [event.target.value])}
                                className={classes.selectEmpty}
                                fullWidth
                                label="Dose"
                            >
                              <MenuItem value={'1'}>1g/1ml</MenuItem>
                              <MenuItem value={'2'}>2g/2ml</MenuItem>
                              <MenuItem value={'3'}>3g/3ml</MenuItem>
                              <MenuItem value={'4'}>4g/4ml</MenuItem>
                              <MenuItem value={'5'}>5g/5ml</MenuItem>
                            </Select>
                          </FormControl>
                        </Description>
                      </DescriptionList>
                    </Grid>
                    }
                  </ImageList>
                  <>
                    <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                      <Grid component="label" container alignItems="center" spacing={1} >
                        <Grid item xs={8}>Lignocaine 1% (w/o adrenaline)</Grid>
                        <Grid item xs={4}>
                          <RadioGroup row aria-label="anaestheticLignocaineOne" name="anaestheticLignocaineOne" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticLignocaineOne ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['anaestheticLignocaineOne'], [event.target.value === 'true'])}}>
                            <FormControlLabel
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      { patientBuffer.circumcision && patientBuffer.circumcision.anaestheticLignocaineOne &&
                      <Grid>
                        <DescriptionList>
                          <Description>
                            <FormControl size="small" variant="outlined" className={classes.formControl} style={{width: '20%', marginTop: 3}}>
                              <InputLabel id="anaesthetic-lignocane-one-amount">Dose</InputLabel>
                              <Select
                                  labelId="anaesthetic-lignocane-one-amount"
                                  id="anaesthetic-lignocane-one-amount-select"
                                  value={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticLignocaineOneAmount ? patientBuffer.circumcision.anaestheticLignocaineOneAmount : ''}
                                  onChange={(event) => updateCircumcisionDataAndDispatch(patient.id, ['anaestheticLignocaineOneAmount'], [event.target.value])}
                                  className={classes.selectEmpty}
                                  fullWidth
                                  label="Dose"
                              >
                                <MenuItem value={'1'}>1ml</MenuItem>
                                <MenuItem value={'2'}>2ml</MenuItem>
                                <MenuItem value={'3'}>3ml</MenuItem>
                                <MenuItem value={'4'}>4ml</MenuItem>
                                <MenuItem value={'5'}>5ml</MenuItem>
                                <MenuItem value={'6'}>6ml</MenuItem>
                                <MenuItem value={'7'}>7ml</MenuItem>
                                <MenuItem value={'8'}>8ml</MenuItem>
                                <MenuItem value={'9'}>9ml</MenuItem>
                                <MenuItem value={'10'}>10ml</MenuItem>
                              </Select>
                            </FormControl>
                          </Description>
                        </DescriptionList>
                      </Grid>
                      }
                    </ImageList>
                    <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                      <Grid component="label" container alignItems="center" spacing={1} >
                        <Grid item xs={8}>Lignocaine 2%  (w/o adrenaline)</Grid>
                        <Grid item xs={4}>
                          <RadioGroup row aria-label="anaestheticLignocaineTwo" name="anaestheticLignocaineTwo" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticLignocaineTwo ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['anaestheticLignocaineTwo'], [event.target.value === 'true'])}}>
                            <FormControlLabel
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      { patientBuffer.circumcision && patientBuffer.circumcision.anaestheticLignocaineTwo &&
                      <Grid>
                        <DescriptionList>
                          <Description>
                            <FormControl size="small" variant="outlined" className={classes.formControl} style={{width: '20%', marginTop: 3}}>
                              <InputLabel id="anaesthetic-lignocane-two-amount">Dose</InputLabel>
                              <Select
                                  labelId="anaesthetic-lignocane-two-amount"
                                  id="anaesthetic-lignocane-two-amount-select"
                                  value={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticLignocaineTwoAmount ? patientBuffer.circumcision.anaestheticLignocaineTwoAmount : ''}
                                  onChange={(event) => updateCircumcisionDataAndDispatch(patient.id, ['anaestheticLignocaineTwoAmount'], [event.target.value])}
                                  className={classes.selectEmpty}
                                  fullWidth
                                  label="Dose"
                              >
                                <MenuItem value={'1'}>1ml</MenuItem>
                                <MenuItem value={'2'}>2ml</MenuItem>
                                <MenuItem value={'3'}>3ml</MenuItem>
                                <MenuItem value={'4'}>4ml</MenuItem>
                                <MenuItem value={'5'}>5ml</MenuItem>
                                <MenuItem value={'6'}>6ml</MenuItem>
                                <MenuItem value={'7'}>7ml</MenuItem>
                                <MenuItem value={'8'}>8ml</MenuItem>
                                <MenuItem value={'9'}>9ml</MenuItem>
                                <MenuItem value={'10'}>10ml</MenuItem>
                              </Select>
                            </FormControl>
                          </Description>
                        </DescriptionList>
                      </Grid>
                      }
                    </ImageList>
                    <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                      <Grid component="label" container alignItems="center" spacing={1} >
                        <Grid item xs={8}>Macaine 0,25%</Grid>
                        <Grid item xs={4}>
                          <RadioGroup row aria-label="anaestheticMacaine" name="anaestheticMacaine" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticMacaine ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['anaestheticMacaine'], [event.target.value === 'true'])}}>
                            <FormControlLabel
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      { patientBuffer.circumcision && patientBuffer.circumcision.anaestheticMacaine &&
                      <Grid>
                        <DescriptionList>
                          <Description>
                            <FormControl size="small" variant="outlined" className={classes.formControl} style={{width: '20%', marginTop: 3}}>
                              <InputLabel id="anaesthetic-macaine-amount">Dose</InputLabel>
                              <Select
                                  labelId="anaesthetic-macaine-amount"
                                  id="anaesthetic-macaine-amount-select"
                                  value={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticMacaineAmount ? patientBuffer.circumcision.anaestheticMacaineAmount : ''}
                                  onChange={(event) => updateCircumcisionDataAndDispatch(patient.id, ['anaestheticMacaineAmount'], [event.target.value])}
                                  className={classes.selectEmpty}
                                  fullWidth
                                  label="Dose"
                              >
                                <MenuItem value={'1'}>1ml</MenuItem>
                                <MenuItem value={'4'}>4ml</MenuItem>
                                <MenuItem value={'8'}>8ml</MenuItem>
                                <MenuItem value={'10'}>10ml</MenuItem>
                              </Select>
                            </FormControl>
                          </Description>
                        </DescriptionList>
                      </Grid>
                      }
                    </ImageList>
                    <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                      <Grid component="label" container alignItems="center" spacing={1} >
                        <Grid item xs={8}>Macaine 0,5%</Grid>
                        <Grid item xs={4}>
                          <RadioGroup row aria-label="anaestheticMacaineDouble" name="anaestheticMacaineDouble" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticMacaineDouble ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['anaestheticMacaineDouble'], [event.target.value === 'true'])}}>
                            <FormControlLabel
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      { patientBuffer.circumcision && patientBuffer.circumcision.anaestheticMacaineDouble &&
                      <Grid>
                        <DescriptionList>
                          <Description>
                            <FormControl size="small" variant="outlined" className={classes.formControl} style={{width: '20%', marginTop: 3}}>
                              <InputLabel id="anaesthetic-macaine-double-amount">Dose</InputLabel>
                              <Select
                                  labelId="anaesthetic-macaine-double-amount"
                                  id="anaesthetic-macaine-double-amount-select"
                                  value={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticMacaineDoubleAmount ? patientBuffer.circumcision.anaestheticMacaineDoubleAmount : ''}
                                  onChange={(event) => updateCircumcisionDataAndDispatch(patient.id, ['anaestheticMacaineDoubleAmount'], [event.target.value])}
                                  className={classes.selectEmpty}
                                  fullWidth
                                  label="Dose"
                              >
                                <MenuItem value={'1'}>1ml</MenuItem>
                                <MenuItem value={'4'}>4ml</MenuItem>
                                <MenuItem value={'8'}>8ml</MenuItem>
                                <MenuItem value={'10'}>10ml</MenuItem>
                              </Select>
                            </FormControl>
                          </Description>
                        </DescriptionList>
                      </Grid>
                      }
                    </ImageList>
                  </>
                  <>
                    <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                      <Grid component="label" container alignItems="center" spacing={1} >
                        <Grid item xs={8}>Ketalar (5%) [IMI] </Grid>
                        <Grid item xs={4}>
                          <RadioGroup row aria-label="anaestheticKetalar" name="anaestheticKetalar" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticKetalar ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['anaestheticKetalar'], [event.target.value === 'true'])}}>
                            <FormControlLabel
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      { patientBuffer.circumcision && patientBuffer.circumcision.anaestheticKetalar &&
                      <Grid>
                        <DescriptionList>
                          <DescriptionTerm>Dose (ml)</DescriptionTerm>
                          <Description>
                            <EditableInput
                                name="dose"
                                type="number"
                                value={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticKetalarAmount ? patientBuffer.circumcision.anaestheticKetalarAmount : ''}
                                disabled={false}
                                fullWidth={false}
                                onSave={(fieldName, value) => updateCircumcisionDataAndDispatch(patient.id, ['anaestheticKetalarAmount'], [value])}
                            />
                          </Description>
                        </DescriptionList>
                      </Grid>
                      }
                    </ImageList>
                    <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                      <Grid component="label" container alignItems="center" spacing={1} >
                        <Grid item xs={8}>Midazolam [Oral] </Grid>
                        <Grid item xs={4}>
                          <RadioGroup row aria-label="anaestheticMidazolam" name="anaestheticMidazolam" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticMidazolam ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['anaestheticMidazolam'], [event.target.value === 'true'])}}>
                            <FormControlLabel
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      { patientBuffer.circumcision && patientBuffer.circumcision.anaestheticMidazolam &&
                      <Grid>
                        <DescriptionList>
                          <Description>
                            <FormControl size="small" variant="outlined" className={classes.formControl} style={{width: '20%', marginTop: 3}}>
                              <InputLabel id="anaesthetic-midazolam-amount">Dose</InputLabel>
                              <Select
                                  labelId="anaesthetic-midazolam-amount"
                                  id="anaesthetic-midazolam-select"
                                  value={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticMidazolamAmount ? patientBuffer.circumcision.anaestheticMidazolamAmount : ''}
                                  onChange={(event) => updateCircumcisionDataAndDispatch(patient.id, ['anaestheticMidazolamAmount'], [event.target.value])}
                                  className={classes.selectEmpty}
                                  fullWidth
                                  label="Dose"
                              >
                                <MenuItem value={'0.5'}>0.5ml</MenuItem>
                                <MenuItem value={'1'}>1ml</MenuItem>
                                <MenuItem value={'1.5'}>1.5ml</MenuItem>
                                <MenuItem value={'2'}>2ml</MenuItem>
                              </Select>
                            </FormControl>
                          </Description>
                        </DescriptionList>
                      </Grid>
                      }
                    </ImageList>
                    <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                      <Grid component="label" container alignItems="center" spacing={1} >
                        <Grid item xs={8}>Pax (5mg) [Oral] </Grid>
                        <Grid item xs={4}>
                          <RadioGroup row aria-label="anaestheticPax" name="anaestheticPax" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticPax ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['anaestheticPax'], [event.target.value === 'true'])}}>
                            <FormControlLabel
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </ImageList>
                    <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                      <Grid component="label" container alignItems="center" spacing={1} >
                        <Grid item xs={8}>Tramazac</Grid>
                        <Grid item xs={4}>
                          <RadioGroup row aria-label="anaestheticTramazac" name="anaestheticTramazac" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticTramazac ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['anaestheticTramazac'], [event.target.value === 'true'])}}>
                            <FormControlLabel
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </ImageList>
                    <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                      <Grid component="label" container alignItems="center" spacing={1} >
                        <Grid item xs={8}>Panado</Grid>
                        <Grid item xs={4}>
                          <RadioGroup row aria-label="anaestheticPanado" name="anaestheticPanado" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.anaestheticPanado ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['anaestheticPanado'], [event.target.value === 'true'])}}>
                            <FormControlLabel
                                value="false"
                                control={<Radio color="primary" />}
                                label="No"
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio color="primary" />}
                                label="Yes"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </ImageList>
                  </>
                  <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Notes</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="notes"
                            value={patientBuffer.circumcision ? patientBuffer.circumcision.notes : ''}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            multiline={true}
                            rows={6}
                            onSave={(fieldName, value) => updateCircumcisionDataAndDispatch(patient.id, [fieldName], [value])}
                        />

                        <Button variant="outlined" color="secondary" onClick={insertStandardNotes}>
                          Insert standard text
                        </Button>
                      </Description>
                    </DescriptionList>
                  </ImageList>
                  <Typography variant="h6" gutterBottom component="div">
                    Applied procedures
                  </Typography>

                  <div className={classes.contentWrapper}>
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item xs={8}>Frenulectomy</Grid>
                      <Grid item xs={4}>
                        <RadioGroup row aria-label="frenulectomy" name="frenulectomy" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.frenulectomy ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['frenulectomy'], [event.target.value === 'true'])}}>
                          <FormControlLabel
                              value="false"
                              control={<Radio color="primary" />}
                              label="No"
                          />
                          <FormControlLabel
                              value="true"
                              control={<Radio color="primary" />}
                              label="Yes"
                          />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={8}>Dorsal slit</Grid>
                      <Grid item xs={4}>
                        <RadioGroup row aria-label="dorsalSlit" name="dorsalSlit" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.dorsalSlit ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['dorsalSlit'], [event.target.value === 'true'])}}>
                          <FormControlLabel
                              value="false"
                              control={<Radio color="primary" />}
                              label="No"
                          />
                          <FormControlLabel
                              value="true"
                              control={<Radio color="primary" />}
                              label="Yes"
                          />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={8}>Dilate prepuce</Grid>
                      <Grid item xs={4}>
                        <RadioGroup row aria-label="dilatePrepuce" name="dilatePrepuce" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.dilatePrepuce ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['dilatePrepuce'], [event.target.value === 'true'])}}>
                          <FormControlLabel
                              value="false"
                              control={<Radio color="primary" />}
                              label="No"
                          />
                          <FormControlLabel
                              value="true"
                              control={<Radio color="primary" />}
                              label="Yes"
                          />
                        </RadioGroup>
                      </Grid>

                      <Grid item xs={8}>Suture</Grid>
                      <Grid item xs={4}>
                        <RadioGroup row aria-label="suture" name="suture" defaultValue={patientBuffer.circumcision && patientBuffer.circumcision.suture ? 'true' : 'false'} onChange={(event) => {updateCircumcisionDataAndDispatch(patient.id, ['suture'], [event.target.value === 'true'])}}>
                          <FormControlLabel
                              value="false"
                              control={<Radio color="primary" />}
                              label="No"
                          />
                          <FormControlLabel
                              value="true"
                              control={<Radio color="primary" />}
                              label="Yes"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </div>
                  <Typography variant="h6" gutterBottom component="div">
                    Happy patient? :)
                  </Typography>

                  <div className={classes.contentWrapper}>
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item xs={8}>Patient likely to provide positive feedback?</Grid>
                      <Grid item xs={4}>
                        <RadioGroup row aria-label="reviewable" name="reviewable" defaultValue={patientBuffer.reviewable ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['reviewable'], [event.target.value === 'true'])}}>
                          <FormControlLabel
                              value="false"
                              control={<Radio color="primary" />}
                              label="No"
                          />
                          <FormControlLabel
                              value="true"
                              control={<Radio color="primary" />}
                              label="Yes"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Follow-up checks
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  { patientBuffer.followUpChecks && patientBuffer.followUpChecks.sort(function(a,b){return new Date(b.date) - new Date(a.date);}).map((followUpCheck) => (
                      <Paper key={followUpCheck.id} elevation={0} variant="outlined" className={classes.paper}>
                        <AppBar className={classes.toolbar}
                                position="static"
                                color="default"
                                elevation={0}>
                          <Toolbar>
                            <Typography variant="subtitle2" gutterBottom component="div">
                              Follow-up {formatTime(followUpCheck.date, 'dd.LL.yyyy')}
                            </Typography>
                          </Toolbar>
                        </AppBar>
                        <div className={classes.contentWrapper}>
                          <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                            <DescriptionList>
                              <DescriptionTerm>Date</DescriptionTerm>
                              <Description>
                                <EditableInput
                                    name="date"
                                    type="date"
                                    value={formatTime(followUpCheck.date, 'dd.LL.yyyy')}
                                    disabled={false}
                                    fullWidth={true}
                                    onSave={(fieldName, value) => updateFollowUpDataAndDispatch(patient.id, followUpCheck.id, [fieldName], [value + ' 00:00:00.000'])}
                                />
                              </Description>
                            </DescriptionList>
                          </ImageList>
                          <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                            <DescriptionList>
                              <DescriptionTerm>Notes</DescriptionTerm>
                              <Description>
                                <EditableInput
                                    name="notes"
                                    value={followUpCheck.notes}
                                    disabled={false}
                                    fullWidth={true}
                                    rows={4}
                                    multiline
                                    size={'large'}
                                    onSave={(fieldName, value) => updateFollowUpDataAndDispatch(patient.id, followUpCheck.id, [fieldName], [value])}
                                />
                              </Description>
                            </DescriptionList>
                          </ImageList>
                          <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item xs={8}>Does patient need another follow-up?</Grid>
                            <Grid item xs={4}>
                              <RadioGroup row aria-label="reviewable" name="reviewable" defaultValue={followUpCheck.needsFollowUp ? 'true' : 'false'} onChange={(event) => {updateFollowUpDataAndDispatch(patient.id, followUpCheck.id,['needsFollowUp'], [event.target.value === 'true'])}}>
                                <FormControlLabel
                                    value="false"
                                    control={<Radio color="primary" />}
                                    label="No"
                                />
                                <FormControlLabel
                                    value="true"
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                />
                              </RadioGroup>
                            </Grid>
                          </Grid>
                          <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                            <DescriptionList>
                              <DescriptionTerm>Days until next follow-up</DescriptionTerm>
                              <Description>
                                <EditableInput
                                    name="nextFollowUpDays"
                                    value={followUpCheck.nextFollowUpDays}
                                    disabled={false}
                                    fullWidth={true}
                                    type="number"
                                    size={'large'}
                                    onSave={(fieldName, value) => updateFollowUpDataAndDispatch(patient.id, followUpCheck.id, [fieldName], [value])}
                                />
                              </Description>
                            </DescriptionList>
                          </ImageList>
                        </div>
                      </Paper>
                  ))}
                  { patientBuffer.followUpChecks && patientBuffer.followUpChecks.length === 0 &&
                  <>
                    <div>No follow-ups have been added yet!</div>
                  </>
                  }
                  <br/>
                  <AddFollowUpDialog confirmationHandler={(date) => addFollowUpDataAndDispatch(patient.id, ['date'], [date + ' 00:00:00.000'])} />
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Adverse events
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  { patientBuffer.circumcision && patientBuffer.circumcision.adverseEvents && patientBuffer.circumcision.adverseEvents.map((adverseEvent) => (
                      <Paper key={adverseEvent.id} elevation={0} variant="outlined" className={classes.paper}>
                        <AppBar className={classes.toolbar}
                                position="static"
                                color="default"
                                elevation={0}>
                          <Toolbar>
                            <Typography variant="subtitle2" gutterBottom component="div">
                              Adverse event {formatTime(adverseEvent.date, 'dd.LL.yyyy')}
                            </Typography>
                          </Toolbar>
                        </AppBar>
                        <div className={classes.contentWrapper}>
                          <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                            <DescriptionList>
                              <DescriptionTerm>Date</DescriptionTerm>
                              <Description>
                                <EditableInput
                                    name="date"
                                    type="date"
                                    value={formatTime(adverseEvent.date, 'dd.LL.yyyy')}
                                    disabled={false}
                                    fullWidth={true}
                                    onSave={(fieldName, value) => updateAdverseEventDataAndDispatch(patient.id, adverseEvent.id, [fieldName], [value + ' 00:00:00.000'])}
                                />
                              </Description>
                            </DescriptionList>
                          </ImageList>
                          <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                            <DescriptionList>
                              <DescriptionTerm>Type</DescriptionTerm>
                              <Description>
                                <EditableInput
                                    name="type"
                                    value={adverseEvent.type}
                                    disabled={false}
                                    fullWidth={true}
                                    size={'large'}
                                    onSave={(fieldName, value) => updateAdverseEventDataAndDispatch(patient.id, adverseEvent.id, [fieldName], [value])}
                                />
                              </Description>
                            </DescriptionList>
                            <DescriptionList>
                              <DescriptionTerm>Severity</DescriptionTerm>
                              <Description>
                                <FormControl size="small" variant="outlined" className={classes.formControl}>
                                  <Select
                                      labelId="severity"
                                      id="severity"
                                      value={adverseEvent.severity}
                                      onChange={(event) => updateAdverseEventDataAndDispatch(patient.id, adverseEvent.id, ['severity'], [event.target.value])}
                                      className={classes.selectEmpty}
                                      fullWidth
                                  >
                                    <MenuItem value={'MILD'}>Mild</MenuItem>
                                    <MenuItem value={'MODERATE'}>Moderate</MenuItem>
                                    <MenuItem value={'SEVERE'}>Severe</MenuItem>
                                  </Select>
                                </FormControl>
                              </Description>
                            </DescriptionList>
                          </ImageList>
                          <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                            <DescriptionList>
                              <DescriptionTerm>Notes</DescriptionTerm>
                              <Description>
                                <EditableInput
                                    name="notes"
                                    value={adverseEvent.notes}
                                    disabled={false}
                                    fullWidth={true}
                                    rows={4}
                                    multiline
                                    size={'large'}
                                    onSave={(fieldName, value) => updateAdverseEventDataAndDispatch(patient.id, adverseEvent.id, [fieldName], [value])}
                                />
                              </Description>
                            </DescriptionList>
                          </ImageList>
                        </div>
                      </Paper>
                  ))}
                  { patientBuffer.circumcision && patientBuffer.circumcision.adverseEvents && patientBuffer.circumcision.adverseEvents.length === 0 &&
                      <>
                        <div>No adverse events have been added yet!</div>
                      </>
                  }
                  <br/>
                  <AddAdverseEventDialog  confirmationHandler={(date, type, severity, notes) => addAdverseEventDataAndDispatch(patient.id, ['date', 'type', 'severity', 'notes'], [date + ' 00:00:00.000', type, severity, notes])}/>
                </div>
              </Paper>
            </Collapse>
          </TableCell>
        </TableRow>
        <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={closeSuccessSnackbar}>
          <Alert severity="success">
            The booking is now confirmed!
          </Alert>
        </Snackbar>
      </React.Fragment>
  );
}

Row.propTypes = {
  patient: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
  }).isRequired,
};


function ConfirmedCircumcisions(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);

  const cancelRequest = useRef();

  const removePatient = async (patientId) => {
    await deletePatient(patientId);
    await fetchData();
  };

  const handleSearchTermChanged = (event) => {
    const term = event.target.value;
    setFilteredPatients(patients.filter(patient => {
      let firstNameTerm = false;
      let lastNameTerm = false;
      let dateOfBirthTerm = false;
      let phoneNumberTerm = false;
      let mobileNumberTerm = false;
      let emailTerm = false;
      let residenceTerm = false;
      let fileNumberTerm = false;

      if (term.includes('-')) {
        if (term.includes('m-') && term.endsWith('m')) {
          if (patient.months === null) {
            return false;
          }

          let stringBeforeMonths = term.substr(0, term.indexOf('m'));
          let stringAfterMonths = term.substring(term.indexOf('-') + 1, term.lastIndexOf('m'));

          if (isNumeric(stringBeforeMonths) && isNumeric(stringAfterMonths)) {
            let months = patient.months;

            return (months >= parseInt(stringBeforeMonths) &&
                months <= parseInt(stringAfterMonths));
          }
        }

        let stringBeforeDash = term.substr(0, term.indexOf('-'));
        let stringAfterDash = term.substr(term.indexOf('-') + 1);

        if (isNumeric(stringBeforeDash) && isNumeric(stringAfterDash)) {
          let age = patient.age;
          return (age >= parseInt(stringBeforeDash) &&
              age <= parseInt(stringAfterDash));
        }
      }

      if (patient.firstName) {
        firstNameTerm =
            patient.firstName.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.lastName) {
        lastNameTerm =
            patient.lastName.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.dateOfBirth) {
        dateOfBirthTerm =
            patient.dateOfBirth.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.email) {
        emailTerm =
            patient.email.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.phoneNumber) {
        phoneNumberTerm =
            patient.phoneNumber.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.mobileNumber) {
        mobileNumberTerm =
            patient.mobileNumber.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.placeOfResidence) {
        residenceTerm =
            patient.placeOfResidence.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.fileNumber) {
        fileNumberTerm = patient.fileNumber.includes(term);
      }

      return firstNameTerm || lastNameTerm || dateOfBirthTerm
          || emailTerm || phoneNumberTerm || mobileNumberTerm || residenceTerm
          || fileNumberTerm;
    }));
  };

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getPatients(true, true, true);
          const patientsData = result.data;

          if (!cancelRequest.current) {
            if (patientsData && Object.keys(patientsData).length > 0) {
              setPatients(patientsData);
              setFilteredPatients(patientsData);
            } else {
              setPatients([]);
              setFilteredPatients([]);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{width: '95%'}}>
            <TextField
                label="Search for patient in list"
                id="patient-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <TableContainer style={{width: '95%'}} component={Paper} className={classes.contentWrapper}>
            <Table aria-label="collapsible table" size="small" >
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell><strong>First name(s)</strong></StyledTableCell>
                  <StyledTableCell><strong>Last name</strong></StyledTableCell>
                  <StyledTableCell><strong>Age</strong></StyledTableCell>
                  <StyledTableCell><strong>Circumcision date</strong></StyledTableCell>
                  <StyledTableCell><strong>Days since circumcision</strong></StyledTableCell>
                  <StyledTableCell><strong>Days since last follow-up</strong></StyledTableCell>
                  <StyledTableCell><strong>Actions</strong></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPatients && filteredPatients.map((patient) => (
                    <Row key={patient.id} patient={patient} fetchData={fetchData} removeHandler={removePatient} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PrintFollowUpListDialog patients={patients} classes={classes}/>
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(ConfirmedCircumcisions);