import React, { useState } from 'react';
import PropTypes from 'prop-types';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon, ListItem,
} from '@mui/material';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItem disableGutters {...props} />)(
    ({ theme }) => ({
      ...theme.typography.body2,
      height: 48,
      position: 'relative',
      textTransform: 'capitalize',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(2.5),
      color: theme.palette.text.secondary,
      '&:before': {
        top: 0,
        right: 0,
        width: 3,
        bottom: 0,
        content: "''",
        display: 'none',
        position: 'absolute',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: theme.palette.primary.main
      }
    })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item }) {
  const theme = useTheme();
  const isActiveRoot = item.path === window.location.pathname;
  const { title, path, info, children } = item;
  const [open, setOpen] =
      useState(children ? children.filter(e => e.path === window.location.pathname).length > 0 : false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
        <>
          <ListItemStyle
              button
              onClick={handleOpen}
              sx={{
                ...((isActiveRoot || children.filter(e => e.path === window.location.pathname).length > 0) && activeRootStyle)
              }}
          >
            <ListItemText disableTypography primary={title} />
            {info && info}
          </ListItemStyle>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = path === window.location.pathname;

                return (
                    <ListItemStyle
                        button
                        component="a"
                        href={path}
                        key={title}
                        sx={{
                          ...(isActiveSub && activeSubStyle)
                        }}
                    >
                      <ListItemIconStyle>
                        <Box
                            component="span"
                            sx={{
                              width: 4,
                              height: 4,
                              display: 'flex',
                              borderRadius: '50%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              bgcolor: 'text.disabled',
                              transition: (theme) => theme.transitions.create('transform'),
                              ...(isActiveSub && {
                                transform: 'scale(2)',
                                bgcolor: 'primary.main'
                              })
                            }}
                        />
                      </ListItemIconStyle>
                      <ListItemText disableTypography primary={title} />
                    </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        </>
    );
  }

  return (
      <ListItemStyle
          key={title}
          button
          component="a"
          href={path}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
      >
        <ListItemText disableTypography primary={title} />
        {info && info}
      </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  return (
      <Box {...other}>
        <List disablePadding>
          {navConfig.map((item) => (
              <NavItem key={item.title} item={item} />
          ))}
        </List>
      </Box>
  );
}
