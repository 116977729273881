import React, {useState} from 'react';
import {getAllCompletedPatients} from "../../utils/api";
import {
  CircularProgress,
  TextField,
  Grid,
  Button,
  Typography,
  Card
} from "@mui/material";
import XLSX from "xlsx";
import {formatTime} from "../../utils/timeHelper";
import {padLeadingZeros} from "../../utils/numbersHelper";
import { withStyles } from '@mui/styles';

const styles = () => ({
  paper: {
    width: '90%',
    padding: 15,
    marginTop: 10,
    marginBottom: '5rem',
  },
});

function UniInfo(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fromAge, setFromAge] = useState(0);
  const [toAge, setToAge] = useState(100);
  const [fromCircDate, setFromCircDate] = useState(undefined);
  const [toCircDate, setToCircDate] = useState(undefined);

  const handleFromAgeChange = (event) => {
    setFromAge(event.target.value);
  };

  const handleToAgeChange = (event) => {
    setToAge(event.target.value);
  };

  const onFromCircDateChange = (e) => {
    setFromCircDate(e.target.value);
  };

  const onToCircDateChange = (e) => {
    setToCircDate(e.target.value);
  };

  const fetchMmcrReport =
      async (loading) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          await getAllCompletedPatients(fromAge > -1 ? fromAge : 0, toAge > -1 ? toAge : 150, fromCircDate, toCircDate).then((response) => {
            setIsLoading(false);
            if (response.data) {
              downloadMmcrReport(response.data);
            }
          });
        } catch (error) {
          console.log(error);
        }
      };

  const fetchExhaustiveReport =
      async (loading) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          await getAllCompletedPatients(fromAge > -1 ? fromAge : 0, toAge > -1 ? toAge : 150, fromCircDate, toCircDate).then((response) => {
            setIsLoading(false);
            if (response.data) {
              downloadUniInfoExhaustivReport(response.data);
            }
          });
        } catch (error) {
          console.log(error);
        }
      };

  const downloadMmcrReport = (patients) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('UniInfo Report');

    let today = new Date();

    var wscols = [
      {wch:10}, {wch:30}, {wch:15}, {wch:10}, {wch:10}, {wch:15}, {wch:15},
      {wch:20}, {wch:15}, {wch:15}, {wch:15}, {wch:15}, {wch:15}, {wch:10},
      {wch:10}, {wch:10}, {wch:10}, {wch:10}, {wch:10},
      {wch:30}, {wch:10}, {wch:10}, {wch:15}, {wch:15}, {wch:15},
      {wch:15}, {wch:15}, {wch:15}, {wch:15}, {wch:15}, {wch:15},
      {wch:15}, {wch:15}, {wch:15},
    ];

    let ws_data = [];
    let headerRow = ['No.', 'Date of MMC', 'File number', '10-14', '15+',
      'N', 'P', 'K', 'D', 'Surgical', 'Device'];

    ws_data.push(headerRow);

    patients.filter(p => p.practice.id !== 2).forEach((p, idx) => {
      let patientRow = [
        idx,
        formatTime(p.circumcisionDate, 'dd.LL.yyyy'),
        padLeadingZeros(p.practice ? p.practice.id : '000', 3) +
        padLeadingZeros(p.id, 6),
        p.age && (p.age < 15 && p.age > 9) ? p.age : '',
        p.age && p.age > 14 ? p.age : '',
        p.hiv ? '' : '',
        p.hiv ? '' : '',
        p.hiv ? 'X' : '',
        p.hiv ? '' : '',
        'X',
        `${p.circumcision && p.circumcision.deviceType ? p.circumcision.deviceType : ''} ${p.circumcision && p.circumcision.device ? p.circumcision.device : ''}`,
      ];

      ws_data.push(patientRow);
    });
    ws_data.push([]);

    wb.Sheets[`UniInfo Report`] = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets[`UniInfo Report`]['!cols'] = wscols;

    XLSX.writeFile(wb, `UniInfo_Circumcision_Register_${today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()}.xlsx`);
  }

  const downloadUniInfoExhaustivReport = (patients) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('UniInfo Report');

    let today = new Date();

    var wscols = [
      {wch:10}, {wch:30}, {wch:15}, {wch:10}, {wch:10}, {wch:15}, {wch:15},
      {wch:20}, {wch:15}, {wch:15}, {wch:15}, {wch:15}, {wch:15}, {wch:10},
      {wch:10}, {wch:10}, {wch:10}, {wch:10}, {wch:10},
      {wch:30}, {wch:10}, {wch:10}, {wch:15}, {wch:15}, {wch:15},
      {wch:15}, {wch:15}, {wch:15}, {wch:15}, {wch:15}, {wch:15},
      {wch:15}, {wch:15}, {wch:15},
    ];

    let ws_data = [];
    let headerRow = ['File number', 'Practice', 'First name', 'Last name',
      'DoB', 'Age', 'Months', 'Phone number 1', 'Phone number 2', 'Place of residence',
      'Campaign', 'Medical aid', 'Reason', 'Date of circumcision',
      'Generally healthy', 'Weight (kg)', 'HIV', 'Tight frenulum', 'Phimosis',
      'Device size', 'Device ID', 'Notes',
      'Frenulectomy', 'Dorsal slit', 'Suture',
      'EMLA amt', 'Lignocaine 1% amt', 'Lignocaine 2% amt',
      'Macaine 0,25% amt', 'Macaine 0,5% amt', 'Ketalar (5%) [IMI] amt',
      'Midazolam [Oral] amt',
      'Pax (5mg) [Oral]', 'Tramazac', 'Panado', 'Adverse event', 'Severity', 'Type', 'Notes'
    ];

    ws_data.push(headerRow);

    patients.filter(p => p.practice.id !== 2).forEach(p => {
      let patientRow = [
        padLeadingZeros(p.practice ? p.practice.id : '000', 3) +
        padLeadingZeros(p.id, 6),
        p.practice.name,
        p.firstName,
        p.lastName,
        formatTime(p.dateOfBirth, 'dd.LL.yyyy'),
        p.age,
        p.age === 0 ? p.months : '',
        p.phoneNumber,
        p.mobileNumber,
        p.placeOfResidence,
        p.campaign,
        p.medicalAid ? 'yes' : 'no',
        p.reasonCircumcision,
        formatTime(p.circumcisionDate, 'dd.LL.yyyy'),
        p.generallyHealthy ? 'yes' : 'no',
        p.weight,
        p.hiv ? 'yes' : 'no',
        p.tightFrenulum ? 'yes' : 'no',
        p.phimosis ? 'yes' : 'no',
        p.circumcision ? p.circumcision.deviceType : '',
        p.circumcision ? p.circumcision.device : '',
        p.circumcision ? p.circumcision.notes : '',
        p.circumcision && p.circumcision.frenulectomy ? 'yes' : 'no',
        p.circumcision && p.circumcision.dorsalSlit ? 'yes' : 'no',
        p.circumcision && p.circumcision.suture ? 'yes' : 'no',
        p.circumcision && p.circumcision.anaestheticEmlaAmount !== undefined
        && p.circumcision.anaestheticEmlaAmount !== null ?
            `${p.circumcision.anaestheticEmlaAmount}ml` : '',
        p.circumcision && p.circumcision.anaestheticLignocaineOneAmount !== undefined
        && p.circumcision.anaestheticLignocaineOneAmount !== null ?
            `${p.circumcision.anaestheticLignocaineOneAmount}ml` : '',
        p.circumcision && p.circumcision.anaestheticLignocaineTwoAmount !== undefined
        && p.circumcision.anaestheticLignocaineTwoAmount !== null ?
            `${p.circumcision.anaestheticLignocaineTwoAmount}ml` : '',
        p.circumcision && p.circumcision.anaestheticMacaineAmount !== undefined
        && p.circumcision.anaestheticMacaineAmount !== null ?
            `${p.circumcision.anaestheticMacaineAmount}ml` : '',
        p.circumcision && p.circumcision.anaestheticMacaineDoubleAmount !== undefined
        && p.circumcision.anaestheticMacaineDoubleAmount !== null ?
            `${p.circumcision.anaestheticMacaineDoubleAmount}ml` : '',
        p.circumcision && p.circumcision.anaestheticKetalarAmount !== undefined
        && p.circumcision.anaestheticMacaineDoubleAmount !== null ?
            `${p.circumcision.anaestheticKetalarAmount}ml` : '',
        p.circumcision && p.circumcision.anaestheticMidazolamAmount !== undefined
        && p.circumcision.anaestheticMacaineDoubleAmount !== null ?
            `${p.circumcision.anaestheticMidazolamAmount}ml` : '',
        p.circumcision && p.circumcision.anaestheticPax ? 'yes' : 'no',
        p.circumcision && p.circumcision.anaestheticTramazac ? 'yes' : 'no',
        p.circumcision && p.circumcision.anaestheticPanado ? 'yes' : 'no',
        p.circumcision && p.circumcision.adverseEvents &&
        p.circumcision.adverseEvents.length > 0 ? 'yes' : 'no',
        p.circumcision && p.circumcision.adverseEvents &&
        p.circumcision.adverseEvents.length > 0 ?
            p.circumcision.adverseEvents[0].severity : '',
        p.circumcision && p.circumcision.adverseEvents &&
        p.circumcision.adverseEvents.length > 0 ?
            p.circumcision.adverseEvents[0].type : '',
        p.circumcision && p.circumcision.adverseEvents &&
        p.circumcision.adverseEvents.length > 0 ?
            p.circumcision.adverseEvents[0].notes : '',
      ];

      ws_data.push(patientRow);
    });
    ws_data.push([]);

    wb.Sheets[`UniInfo Report`] = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets[`UniInfo Report`]['!cols'] = wscols;

    XLSX.writeFile(wb, `UniInfo_Report_${today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()}.xlsx`);
  }

  const renderData = () => {
    return (
        <>
          <Card className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant="h6" color="textPrimary">
                  Generate UniInfo report (XLSX)
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant="subtitle1" color="textPrimary">
                  Age range
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="fromAge"
                    label="From"
                    type="number"
                    variant={'outlined'}
                    value={fromAge}
                    onChange={handleFromAgeChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="toAge"
                    label="To"
                    type="number"
                    variant={'outlined'}
                    value={toAge}
                    onChange={handleToAgeChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant="subtitle1" color="textPrimary">
                  Circumcision date range
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                    id="fromDate"
                    type="date"
                    variant={'outlined'}
                    value={fromCircDate}
                    onChange={onFromCircDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                    id="toDate"
                    type="date"
                    variant={'outlined'}
                    value={toCircDate}
                    onChange={onToCircDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Button
                    style={{marginTop: 22}}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => fetchMmcrReport(true)}>
                  MMC Register
                </Button>
                <Button
                    style={{marginTop: 22, marginLeft: 22}}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => fetchExhaustiveReport(true)}>
                  Exhaustive report
                </Button>
              </Grid>
            </Grid>
          </Card>
          { isLoading &&
          <Grid item xs={6} md={2} xl={2}>
            <CircularProgress size={72} color="primary" className={classes.paper}/>
          </Grid>
          }
        </>

    );
  };

  return renderData();
}

export default withStyles(styles)(UniInfo);