import React, {useState} from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { withStyles } from '@mui/styles';
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../layouts/Description.parts";

const styles = (theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 240,
  },
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function AddAdverseEventDialog(props) {
  const { classes, confirmationHandler } = props;
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(undefined);
  const [severity, setSeverity] = useState('');
  const [type, setType] = useState('');
  const [notes, setNotes] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    confirmationHandler(
        date,
        type,
        severity,
        notes
    );
    handleClose();
  };

  const clearFormData = () => {
    setDate(undefined);
    setSeverity('');
    setType('');
    setNotes('');
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleSeverityChange = (event) => {
    setSeverity(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  return (
      <>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Add adverse event
        </Button>
        <Dialog fullWidth={true} maxWidth={'sm'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">New adverse event</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the necessary information
            </DialogContentText>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  variant="outlined"
                  id="adverse-event-date"
                  label="Date"
                  type="date"
                  value={date}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
            <br/>
            <DescriptionList>
              <DescriptionTerm>Type</DescriptionTerm>
              <Description>
                <Select
                    labelId="type-select"
                    id="type-select"
                    value={type}
                    className={classes.selectEmpty}
                    onChange={handleTypeChange}
                    variant={'outlined'}
                >
                  <MenuItem value={'Blood loss'}>Blood loss</MenuItem>
                  <MenuItem value={'Wound infection'}>Wound infection</MenuItem>
                  <MenuItem value={'Wound disruption'}>Wound disruption</MenuItem>
                </Select>
              </Description>
            </DescriptionList>
            <br/>
            <div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="severity-select-helper-label">Severity</InputLabel>
                <Select
                    labelId="severity-select-helper-label"
                    id="severity-select"
                    label="Severity"
                    value={severity}
                    onChange={handleSeverityChange}
                    className={classes.selectEmpty}
                    fullWidth
                >
                  <MenuItem value={'MILD'}>Mild</MenuItem>
                  <MenuItem value={'MODERATE'}>Moderate</MenuItem>
                  <MenuItem value={'SEVERE'}>Severe</MenuItem>
                </Select>
              </FormControl>
            </div>
            <br/>
            <div>
              <TextField
                  id="notes"
                  label="Notes"
                  placeholder="Notes"
                  type="text"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth={true}
                  value={notes}
                  onChange={handleNotesChange}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Abort
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default withStyles(styles)(AddAdverseEventDialog)