import AddIcon from "@mui/icons-material/Add";


const sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: AddIcon
  },
  {
    title: 'Patients',
    icon: AddIcon,
    children: [
      { title: 'Requests', path: '/request' },
      { title: 'Appointments', path: '/appointments' },
      { title: 'Pre-checked', path: '/pre-checked' },
      { title: 'Circumcised', path: '/circumcised' },
    ]
  },
  {
    title: 'Archive',
    icon: AddIcon,
    children: [
      { title: 'Completed', path: '/completed' },
      { title: 'No-show', path: '/no-show' },
      { title: 'Deferred', path: '/deferred' },
      { title: 'Ineligible', path: '/ineligible' },
    ]
  },
  {
    title: 'Training',
    path: '/trainees',
    icon: AddIcon
  },
  {
    title: 'Admin',
    icon: AddIcon,
    children: [
      { title: 'UniInfo', path: '/uni-info' },
      { title: 'User Management', path: '/users' },
      { title: 'Profile', path: '/profile' },
    ]
  }
];

export default sidebarConfig;
