import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA9NlKrhhd4T2PozZK0g1Uo2PH9UgbLM9Q",
  authDomain: "unicirc.firebaseapp.com",
  projectId: "unicirc",
  storageBucket: "unicirc.appspot.com",
  messagingSenderId: "497363042229",
  appId: "1:497363042229:web:cb2699fd60b3edc8b9a3fc"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();