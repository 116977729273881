import React, {useContext, useState} from 'react';
import { IconButton, Tooltip, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Checkbox } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {UserContext} from "../auth/UserProvider";
import { withStyles } from '@mui/styles';


const styles = () => ({
  block: {
    display: 'block',
  },
});

function DataGridActionBar(props) {
  const { classes, data, type, deleteHandler, relations } = props;

  const authState = useContext(UserContext);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [hardDelete, setHardDelete] = useState(false);

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteConfirm = () => {
    deleteHandler(data.id, hardDelete ? 'HARD' : 'SOFT');
    handleDeleteClose();
  };

  const handleHardDeleteChange = (event) => {
    setHardDelete(event.target.checked);
  };

  return (
      <>
        { (authState.role === 'ROLE_SUPER' || authState.role === 'ROLE_STANDARD') &&
          <>
            <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={handleDeleteOpen}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={deleteOpen} onClose={handleDeleteClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Delete {type ? type.toLowerCase() : ''}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <p>
                    Deletion {type ? 'of ' + type.toLowerCase() : ''} will fail if it is used by other objects! Related objects are:
                    { relations &&
                      <ul>
                        { relations.map((relation) => (
                          <li>{relation}</li>
                        ))}
                      </ul>
                    }
                  </p>
                  <p>
                    Are you sure?
                  </p>
                  <FormControlLabel
                      control={
                        <Checkbox
                            checked={hardDelete}
                            onChange={handleHardDeleteChange}
                            name="hardDelete"
                        />
                      }
                      label="Delete permanently"
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDeleteConfirm} color="primary">
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </>
        }
      </>
  );
}

export default withStyles(styles)(DataGridActionBar)