import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import AcceptIcon from '@mui/icons-material/Check';
import RejectIcon from '@mui/icons-material/Close';

import { ButtonWithIcon } from './EditableInput.parts';

const fadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ControlButtons = styled.div`
  display: inline-flex;
  align-items: center;
  animation: ${fadeAnimation} 250ms linear;

  ${props =>
    props.floating
        ? `
      margin-top: ${props.theme.spacing(0.4)}px;
      position: absolute;
      right: 0px;
      top: 100%;
      `
        : ''}
`;

const AcceptRejectButtons = ({ floating, onAccept, onReject }) => {

  return (
      <ControlButtons floating={floating}>
        <ButtonWithIcon aria-label={'Confirmer'} buttonsize={'small'} onClick={onAccept}>
          <AcceptIcon />
        </ButtonWithIcon>
        <ButtonWithIcon aria-label={'Annuler'} buttonsize={'small'} onClick={onReject}>
          <RejectIcon />
        </ButtonWithIcon>
      </ControlButtons>
  );
};

AcceptRejectButtons.defaultProps = {
  floating: false,
  size: 'small',
};

AcceptRejectButtons.propTypes = {
  floating: PropTypes.bool,
  size: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default AcceptRejectButtons;
