import React, {useState} from "react";
import PropTypes from "prop-types";
import { withStyles } from '@mui/styles';
import { Hidden } from "@mui/material";
import { styled } from '@mui/material/styles';

import { BrowserRouter, Switch, useLocation } from 'react-router-dom';
import Navigator from "./Navigator";

import PatientRegistration from "./circumcision/PatientRegistration";
import ConfirmedCircumcisions from "./circumcision/ConfirmedCircumcisions";
import ProtectedRoute from "./auth/ProtectedRoute";
import PrecheckedPatients from "./circumcision/PrecheckedPatients";
import CircumcisedPatients from "./circumcision/CircumcisedPatients";
import Trainees from "./training/Trainees";
import CompletedPatients from "./circumcision/CompletedPatients";
import UninterestedPatients from "./circumcision/UninterestedPatients";
import DeferredPatients from "./circumcision/DeferredPatients";
import IneligiblePatients from "./circumcision/IneligiblePatients";
import Dashboard from "./dashboard/Dashboard";
import UserSettings from "./auth/UserSettings";
import UniInfo from "./admin/UniInfo";
import Header from "./Header";


const drawerWidth = 256;
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const styles = {
  root: {
    display: "flex",
    minHeight: '100%',
    overflow: 'hidden',
  },
  appContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
};

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

function Paperbase(props) {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
      <MainStyle>
        <div className={classes.root}>
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                  PaperProps={{ style: { width: drawerWidth, backgroundColor: '#E4F0E7' } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator PaperProps={{ elevation: 6, style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
          <div className={classes.appContent}>
            <BrowserRouter>
              <Switch>
                <ProtectedRoute exact path={["/dashboard", "/"]}>
                  <>
                    <Header onOpenSidebar={() => setOpen(true)} />
                    <main>
                      <Dashboard />
                    </main>
                  </>
                </ProtectedRoute>
                <ProtectedRoute exact path={"/request"}>
                  <>
                    <Header onOpenSidebar={() => setOpen(true)} />
                    <main>
                      <PatientRegistration />
                    </main>
                  </>
                </ProtectedRoute>
                <ProtectedRoute exact path="/appointments">
                  <>
                    <Header onOpenSidebar={() => setOpen(true)} />
                    <main>
                      <ConfirmedCircumcisions />
                    </main>
                  </>
                </ProtectedRoute>
                <ProtectedRoute exact path="/pre-checked">
                  <>
                    <main>
                      <PrecheckedPatients />
                    </main>
                  </>
                </ProtectedRoute>
                <ProtectedRoute exact path="/circumcised">
                  <>
                    <Header onOpenSidebar={() => setOpen(true)} />
                    <main>
                      <CircumcisedPatients />
                    </main>
                  </>
                </ProtectedRoute>
                <ProtectedRoute exact path="/completed">
                  <>
                    <Header onOpenSidebar={() => setOpen(true)} />
                    <main>
                      <CompletedPatients />
                    </main>
                  </>
                </ProtectedRoute>
                <ProtectedRoute exact path="/no-show">
                  <>
                    <Header onOpenSidebar={() => setOpen(true)} />
                    <main>
                      <UninterestedPatients />
                    </main>
                  </>
                </ProtectedRoute>
                <ProtectedRoute exact path="/deferred">
                  <>
                    <Header onOpenSidebar={() => setOpen(true)} />
                    <main>
                      <DeferredPatients />
                    </main>
                  </>
                </ProtectedRoute>
                <ProtectedRoute exact path="/ineligible">
                  <>
                    <Header onOpenSidebar={() => setOpen(true)} />
                    <main>
                      <IneligiblePatients />
                    </main>
                  </>
                </ProtectedRoute>
                <ProtectedRoute exact path="/trainees">
                  <>
                    <Header onOpenSidebar={() => setOpen(true)} />
                    <main>
                      <Trainees />
                    </main>
                  </>
                </ProtectedRoute>
                <ProtectedRoute exact path="/uni-info">
                  <>
                    <Header onOpenSidebar={() => setOpen(true)} />
                    <main className={classes.main}>
                      <UniInfo />
                    </main>
                  </>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/profile", "/users"]}>
                  <>
                    <Header onOpenSidebar={() => setOpen(true)} />
                    <main className={classes.main}>
                      <UserSettings />
                    </main>
                  </>
                </ProtectedRoute>
              </Switch>
            </BrowserRouter>
          </div>
        </div>
      </MainStyle>
  );
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Paperbase);
