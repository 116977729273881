import React, {useState} from 'react';
import { Button, Fab, Autocomplete, createFilterOptions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControl } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { withStyles } from '@mui/styles';

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  formControl: {
    marginTop: '1rem'
  }
});

function AddPatientDialog(props) {
  const { classes, confirmationHandler, residenceOptions, reasonOptions } = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [mobileNumber, setMobileNumber] = useState(undefined);
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(undefined);
  const [placeOfResidence, setPlaceOfResidence] = useState('');
  const [reasonCircumcision, setReasonCircumcision] = useState('');
  const [open, setOpen] = useState(false);

  const filter = createFilterOptions();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    confirmationHandler(
        firstName,
        lastName,
        dateOfBirth,
        phoneNumber,
        mobileNumber,
        email,
        placeOfResidence,
        reasonCircumcision
    );
    handleClose();
  };

  const clearFormData = () => {
    setFirstName('');
    setLastName('');
    setPhoneNumber(undefined);
    setMobileNumber(undefined);
    setEmail('');
    setDateOfBirth(undefined);
    setPlaceOfResidence('');
    setReasonCircumcision('');
  };

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const onlastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const onPhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const onMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };


  const onDateOfBirthChange = (e) => {
    setDateOfBirth(e.target.value);
  };

  return (
      <div>
        <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed'}}
        >
          <AddIcon/>
          Add new patient
        </Fab>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add new patient</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the patients details
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="first-name"
                    label="First name(s)"
                    type="text"
                    value={firstName}
                    variant={'outlined'}
                    onChange={onFirstNameChange}
                />
              </FormControl>
              {'    '}
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="last-name"
                    label="Last name"
                    type="text"
                    value={lastName}
                    variant={'outlined'}
                    onChange={onlastNameChange}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    id="date"
                    label="Date of birth"
                    type="date"
                    variant={'outlined'}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dateOfBirth}
                    onChange={onDateOfBirthChange}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="phone-number"
                    label="Phone number #1"
                    type="number"
                    value={phoneNumber}
                    variant={'outlined'}
                    onChange={onPhoneNumberChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="mobile-number"
                    label="Phone number #2"
                    type="number"
                    value={mobileNumber}
                    variant={'outlined'}
                    onChange={onMobileNumberChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="email"
                    label="Email"
                    type="text"
                    value={email}
                    variant={'outlined'}
                    onChange={onEmailChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <Autocomplete className={classes.formControl}
                  value={placeOfResidence}
                  onChange={(event, newValue) => {
                    if (newValue && newValue.startsWith('Add ')) {
                      setPlaceOfResidence(newValue.substring(4).replace(/"/g, ''));
                    } else {
                      setPlaceOfResidence(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push(`Add "${params.inputValue}"`);
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="residence-select"
                  options={residenceOptions}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    console.log(option);
                    
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option;
                  }}
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  style={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => (
                      <TextField {...params} label="Place of residence" variant="outlined" />
                  )}
              />
            </div>
            <div>
              <Autocomplete className={classes.formControl}
                  value={reasonCircumcision}
                  onChange={(event, newValue) => {
                    if (newValue && newValue.startsWith('Add ')) {
                      setReasonCircumcision(newValue.substring(4).replace(/"/g, ''));
                    } else {
                      setReasonCircumcision(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push(`Add "${params.inputValue}"`);
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="reason-circumcision-select"
                  options={reasonOptions}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option;
                  }}
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  style={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => (
                      <TextField {...params} label="Reason for circumcision" variant="outlined" />
                  )}
              />
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddPatientDialog)
