import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@mui/styles';
import { ImageList, Snackbar, Toolbar, AppBar, Typography, Collapse, IconButton, Table, TableHead, TableBody, TableRow, CircularProgress, TableCell, TableContainer, TextField, Paper } from "@mui/material";
import MuiAlert from '@mui/lab/Alert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TrueIcon from '@mui/icons-material/CheckCircle';
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../layouts/Description.parts";
import EditableInput from "../input/EditableInput";
import {
  createPatient,
  deletePatient,
  getTrainees, updatePatient
} from "../../utils/api";
import {formatTime, getAge} from "../../utils/timeHelper";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
  contentWrapper: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgba(27, 66, 136, 1)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function Row(props) {
  let { patient, fetchData, removeHandler } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const cancelRequest = useRef();
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [patientBuffer, setPatientBuffer] = useState(patient);

  const showSuccessSnackbar = () => {
    setSuccessSnackbarOpen(true);
  }

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackbarOpen(false);
  };

  const updatePatientDataAndDispatch = async (patientId, fields, values) => {
    try {
      let pBuffer = {
        ...patientBuffer
      };

      let updatedFields = {};

      for (let i = 0; i < fields.length; i++) {
        pBuffer[fields[i]] = values[i];
        updatedFields[fields[i]] = values[i];
      }

      setPatientBuffer(pBuffer);

      const { data: patientData } = await updatePatient(
          patientId,
          updatedFields
      );

      if (!cancelRequest.current) {
        setPatientBuffer(patientData);
      }
    } catch (error) {
      setPatientBuffer(patientBuffer);
    }
  };

  return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{patient.title}</TableCell>
          <TableCell>{patient.firstName}</TableCell>
          <TableCell>{patient.lastName}</TableCell>
          <TableCell>{patient.email}</TableCell>
          <TableCell>{patient.phone}</TableCell>
          <TableCell>{patient.institute}</TableCell>
          <TableCell>{patient.occupation}</TableCell>
          <TableCell>{patient.distributor ? <TrueIcon /> : ''}</TableCell>
          <TableCell>{patient.accreditedTrainer ? <TrueIcon /> : ''}</TableCell>
          <TableCell>
            <ConfirmActionDialog action={'delete'} confirmationHandler={() => removeHandler(patient.id)}/>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
            <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
              <Typography variant="h5" gutterBottom component="div">
                Patient details
              </Typography>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      General information
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>First name</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="firstName"
                            value={patientBuffer.firstName}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Last name</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="lastName"
                            value={patientBuffer.lastName}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Date of birth</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="dateOfBirth"
                            type="date"
                            value={formatTime(patientBuffer.dateOfBirth, 'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Age</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="age"
                            type="number"
                            value={getAge(patientBuffer.dateOfBirth)}
                            disabled={true}
                            fullWidth={true}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Phone number #1</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="phoneNumber"
                            type="text"
                            value={patientBuffer.phoneNumber}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Phone number #2</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="mobileNumber"
                            type="text"
                            value={patientBuffer.mobileNumber}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Email</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="email"
                            type="text"
                            value={patientBuffer.email}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Place of residence</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="placeOfResidence"
                            type="text"
                            value={patientBuffer.placeOfResidence}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Reason for circumcision</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="reasonCircumcision"
                            type="text"
                            value={patientBuffer.reasonCircumcision}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                </div>
              </Paper>
            </Collapse>
          </TableCell>
        </TableRow>
        <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={closeSuccessSnackbar}>
          <Alert severity="success">
            The booking is now confirmed!
          </Alert>
        </Snackbar>
      </React.Fragment>
  );
}

Row.propTypes = {
  patient: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
  }).isRequired,
};

function Trainees(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [patients, setTrainees] = useState([]);
  const [filteredPatients, setFilteredTrainees] = useState([]);

  const cancelRequest = useRef();

  const handleSearchTermChanged = (event) => {
    const term = event.target.value;
    setFilteredTrainees(patients.filter(patient => {
      let firstNameTerm = false;
      let lastNameTerm = false;
      let phoneNumberTerm = false;
      let emailTerm = false;
      let instituteTerm = false;
      let occupationTerm = false;

      if (patient.firstName) {
        firstNameTerm =
            patient.firstName.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.lastName) {
        lastNameTerm =
            patient.lastName.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.email) {
        emailTerm =
            patient.email.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.phone) {
        phoneNumberTerm =
            patient.phone.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.institute) {
        instituteTerm =
            patient.institute.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.occupation) {
        occupationTerm =
            patient.occupation.toLowerCase().includes(term.toLowerCase());
      }

      return firstNameTerm || lastNameTerm  || emailTerm || phoneNumberTerm
          || instituteTerm || occupationTerm;
    }));
  };

  const removeTrainee = async (patientId) => {
    await deletePatient(patientId);
    await fetchData();
  };

  const addConfirmationHandler =
      async (firstName, lastName, dateOfBirth, age,
          monthsOfAge, phoneNumber, mobileNumber, email, placeOfResidence,
          reasonCircumcision) => {
        await createPatient({
          'firstName': firstName,
          'lastName': lastName,
          'dateOfBirth': dateOfBirth,
          'age': age,
          'months': monthsOfAge,
          'phoneNumber': phoneNumber,
          'mobileNumber': mobileNumber,
          'email': email,
          'placeOfResidence': placeOfResidence,
          'reasonCircumcision': reasonCircumcision
        });
        await fetchData();
      };

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getTrainees();
          const traineeData = result.data;

          if (!cancelRequest.current) {
            if (traineeData) {
              traineeData.forEach(trainee => {
                trainee.age = getAge(trainee.dateOfBirth);
              })
              setTrainees(traineeData);
              setFilteredTrainees(traineeData);
            } else {
              setTrainees([]);
              setFilteredTrainees([]);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{width: '95%'}}>
            <TextField
                label="Search for trainee in list"
                id="trainee-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <TableContainer style={{width: '95%'}} component={Paper} className={classes.contentWrapper}>
            <Table aria-label="collapsible table" size="small" >
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell><strong>Title</strong></StyledTableCell>
                  <StyledTableCell><strong>First name(s)</strong></StyledTableCell>
                  <StyledTableCell><strong>Last name</strong></StyledTableCell>
                  <StyledTableCell><strong>Email</strong></StyledTableCell>
                  <StyledTableCell><strong>Phone</strong></StyledTableCell>
                  <StyledTableCell><strong>Institute</strong></StyledTableCell>
                  <StyledTableCell><strong>Occupation</strong></StyledTableCell>
                  <StyledTableCell><strong>Distributor</strong></StyledTableCell>
                  <StyledTableCell><strong>Accredited Trainer</strong></StyledTableCell>
                  <StyledTableCell><strong>Actions</strong></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPatients && filteredPatients.map((trainee) => (
                    <Row key={trainee.id} patient={trainee} fetchData={fetchData} removeHandler={removeTrainee} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/*<AddTraineeDialog classes={classes} confirmationHandler={addConfirmationHandler}/>*/}
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Trainees);