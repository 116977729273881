import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer, } from '@mui/material';
// components
import Scrollbar from '../components/Scrollbar';
import NavSection from '../components/NavSection';
import { MHidden } from '../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

Navigator.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function Navigator({ isOpenSidebar, onCloseSidebar }) {
  const pathname  = window.location.pathname;

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
      <Scrollbar
          sx={{
            height: '100%',
            '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
          }}
      >
        <Box sx={{ px: 2.5, py: 3 }}>
          <img style={{marginLeft: '0.5rem'}} alt={'Unicirc Logo'} width={224} height={81} src={'unicirc-logo.png'} />
        </Box>

        <NavSection navConfig={sidebarConfig} />

        <Box sx={{ mt: 5 }}>
          <img style={{ opacity: 0.2 }} alt={'Unicirc Elephant'} src={'unicirc_elephant_small.png'} />
        </Box>

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
  );

  return (
      <RootStyle>
        <MHidden width="lgUp">
          <Drawer
              open={isOpenSidebar}
              onClose={onCloseSidebar}
              PaperProps={{
                sx: { width: DRAWER_WIDTH }
              }}
          >
            {renderContent}
          </Drawer>
        </MHidden>

        <MHidden width="lgDown">
          <Drawer
              open
              variant="persistent"
              PaperProps={{
                sx: {
                  width: DRAWER_WIDTH,
                  bgcolor: 'background.default'
                }
              }}
          >
            {renderContent}
          </Drawer>
        </MHidden>
      </RootStyle>
  );
}
