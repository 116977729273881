import React, {useEffect, useRef, useState} from 'react';
import {
  deletePatient, getIneligiblePatients,
  updatePatient
} from "../../utils/api";
import { withStyles, makeStyles } from '@mui/styles';
import { Alert, Checkbox, Snackbar, Radio, FormControlLabel, RadioGroup, Grid, ImageList, Toolbar, AppBar, Typography, Collapse, IconButton, Table, TableHead, TableBody, TableRow, CircularProgress, TableCell, TableContainer, TextField, Paper } from "@mui/material";
import {formatTime} from "../../utils/timeHelper";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../layouts/Description.parts";
import EditableInput from "../input/EditableInput";
import PropTypes from "prop-types";
import {isNumeric} from "../../utils/numbersHelper";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
  contentWrapper: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgba(27, 66, 136, 1)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function Row(props) {
  let { patient, removeHandler } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const cancelRequest = useRef();
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [patientBuffer, setPatientBuffer] = useState(patient);


  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackbarOpen(false);
  };

  const updatePatientDataAndDispatch = async (patientId, fields, values) => {
    try {
      let pBuffer = {
        ...patientBuffer
      };

      let updatedFields = {};

      for (let i = 0; i < fields.length; i++) {
        pBuffer[fields[i]] = values[i];
        updatedFields[fields[i]] = values[i];
      }

      setPatientBuffer(pBuffer);

      const { data: patientData } = await updatePatient(
          patientId,
          updatedFields
      );

      if (!cancelRequest.current) {
        setPatientBuffer(patientData);
      }
    } catch (error) {
      setPatientBuffer(patientBuffer);
    }
  };

  const ageString = `${patientBuffer.age !== null ? patientBuffer.age : ''} ${patientBuffer.months !== null && patientBuffer.age < 1 ? ' (' + patientBuffer.months + ' ' + (patientBuffer.months === 1 ? 'month' : 'months') + ')' : ''}`;

  return (
      <React.Fragment>
        <TableRow className={classes.root} style={patientBuffer.problemPatient ? {backgroundColor: '#ffcccb'} : {backgroundColor: '#ffffff'}}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{patientBuffer.firstName}</TableCell>
          <TableCell>{patientBuffer.lastName}</TableCell>
          <TableCell>{formatTime(patientBuffer.dateOfBirth, 'dd.LL.yyyy')}</TableCell>
          <TableCell>{ageString}</TableCell>
          <TableCell>{patientBuffer.phoneNumber}</TableCell>
          <TableCell>{patientBuffer.placeOfResidence}</TableCell>
          <TableCell>
            <ConfirmActionDialog action={'delete'} confirmationHandler={() => removeHandler(patient.id)}/>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
              <Typography variant="h5" gutterBottom component="div">
                Patient details
              </Typography>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      General information
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>File number</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="fileNumber"
                            value={patientBuffer.fileNumber}
                            disabled={true}
                            fullWidth={true}
                            size={'large'}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                  <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>First name</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="firstName"
                            value={patientBuffer.firstName}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Last name</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="lastName"
                            value={patientBuffer.lastName}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Date of birth</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="dateOfBirth"
                            type="date"
                            value={formatTime(patientBuffer.dateOfBirth, 'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Age</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="age"
                            value={ageString}
                            disabled={true}
                            fullWidth={true}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Phone number #1</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="phoneNumber"
                            type="text"
                            value={patientBuffer.phoneNumber}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Phone number #2</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="mobileNumber"
                            type="text"
                            value={patientBuffer.mobileNumber}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Email</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="email"
                            type="text"
                            value={patientBuffer.email}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Place of residence</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="placeOfResidence"
                            type="text"
                            value={patientBuffer.placeOfResidence}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Reason for circumcision</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="reasonCircumcision"
                            type="text"
                            value={patientBuffer.reasonCircumcision}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item xs={8}>Problem patient?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="problemPatient" name="problemPatient" defaultValue={patientBuffer.problemPatient ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['problemPatient'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item xs={6}>
                      <DescriptionList>
                        <DescriptionTerm>Reason</DescriptionTerm>
                        <Description>
                          <EditableInput
                              name="problemPatientNotes"
                              value={patientBuffer.problemPatientNotes}
                              disabled={false}
                              fullWidth={true}
                              size={'large'}
                              rows={4}
                              multiline={true}
                              onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                          />
                        </Description>
                      </DescriptionList>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Appointment info
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Date of circumcision</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="circumcisionDate"
                            type="datetime-local"
                            value={formatTime(patientBuffer.circumcisionDate, 'dd.LL.yyyy HH:mm')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value.replace('T', ' ').concat(':00.000')])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Medical aid</DescriptionTerm>
                      <Description>
                        <Checkbox
                            checked={patientBuffer.medicalAid}
                            onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['medicalAid'], [event.target.checked])}}
                            name="medicalAid"
                            inputProps={{ 'aria-label': 'medical aid checkbox' }}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                </div>
              </Paper>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      Pre-check data
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item xs={8}>Generally healthy?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="generallyHealthy" name="generallyHealthy" defaultValue={patientBuffer.generallyHealthy ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['generallyHealthy'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Significant illness?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="significantIllness" name="significantIllness" defaultValue={patientBuffer.significantIllness ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['significantIllness'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Experiencing urethral discharge?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="urethralDischarge" name="urethralDischarge" defaultValue={patientBuffer.urethralDischarge ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['urethralDischarge'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Currently taking aspirin?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="takingAspirin" name="takingAspirin" defaultValue={patientBuffer.takingAspirin ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['takingAspirin'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Past reaction to local anesthetic?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="pastReactionToLocalAnesthetic" name="pastReactionToLocalAnesthetic" defaultValue={patientBuffer.pastReactionToLocalAnesthetic ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['pastReactionToLocalAnesthetic'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Known bleeding disorder or tends to bleed profusely when cut?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="bleedingDisorder" name="bleedingDisorder" defaultValue={patientBuffer.bleedingDisorder ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['bleedingDisorder'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Genital sore (ulcer)?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="genitalSore" name="genitalSore" defaultValue={patientBuffer.genitalSore ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['genitalSore'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Pain on erection?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="painOnErection" name="painOnErection" defaultValue={patientBuffer.painOnErection ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['painOnErection'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Swelling of the scrotum?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="swellingScrotum" name="swellingScrotum" defaultValue={patientBuffer.swellingScrotum ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['swellingScrotum'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Pain on urination?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="painOnUrination" name="painOnUrination" defaultValue={patientBuffer.painOnUrination ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['painOnUrination'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Difficulty in retracting foreskin?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="difficultyRetractingForeskin" name="difficultyRetractingForeskin" defaultValue={patientBuffer.difficultyRetractingForeskin ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['difficultyRetractingForeskin'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Hypertension?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="hypertension" name="hypertension" defaultValue={patientBuffer.hypertension ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['hypertension'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Fever / Chills?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="feverChills" name="feverChills" defaultValue={patientBuffer.feverChills ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['feverChills'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Shortness of Breath?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="shortnessOfBreath" name="shortnessOfBreath" defaultValue={patientBuffer.shortnessOfBreath ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['shortnessOfBreath'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Abdominal pain?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="abdominalPain" name="abdominalPain" defaultValue={patientBuffer.abdominalPain ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['abdominalPain'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Open cuts or bruises?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="bruises" name="bruises" defaultValue={patientBuffer.bruises ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['bruises'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Rash?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="rash" name="rash" defaultValue={patientBuffer.rash ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['rash'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Diabetes?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="diabetes" name="diabetes" defaultValue={patientBuffer.diabetes ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['diabetes'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Any medication allergies?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="medicationAllergies" name="medicationAllergies" defaultValue={patientBuffer.medicationAllergies ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['medicationAllergies'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Treated for any sexually transmitted infections in past month?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="treatedForStd" name="treatedForStd" defaultValue={patientBuffer.treatedForStd ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['treatedForStd'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={8}>Abnormalities on foreskin?</Grid>
                    <Grid item xs={4}>
                      <RadioGroup row aria-label="abnormalForeskin" name="abnormalForeskin" defaultValue={patientBuffer.abnormalForeskin ? 'true' : 'false'} onChange={(event) => {updatePatientDataAndDispatch(patient.id, ['abnormalForeskin'], [event.target.value === 'true'])}}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="No"
                        />
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Yes"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <br/>
                  <ImageList rowHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Blood pressure - Systolic (mmHg)</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="bloodPressureTop"
                            value={patientBuffer.bloodPressureTop}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Blood pressure - Diastolic (mmHg)</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="bloodPressureBottom"
                            value={patientBuffer.bloodPressureBottom}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Weight (kg)</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="weight"
                            value={patientBuffer.weight}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Blood Sugar Level (mmol/L)</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="bloodSugarLevel"
                            value={patientBuffer.bloodSugarLevel}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Temperature (°C)</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="temperature"
                            value={patientBuffer.temperature}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            onSave={(fieldName, value) => updatePatientDataAndDispatch(patient.id, [fieldName], [value])}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                </div>
              </Paper>
            </Collapse>
          </TableCell>
        </TableRow>
        <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={closeSuccessSnackbar}>
          <Alert severity="success">
            Final examination done!
          </Alert>
        </Snackbar>
      </React.Fragment>
  );
}

Row.propTypes = {
  patient: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
  }).isRequired,
};


function IneligiblePatients(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);

  const cancelRequest = useRef();

  const removePatient = async (patientId) => {
    await deletePatient(patientId);
    await fetchData();
  };

  const handleSearchTermChanged = (event) => {
    const term = event.target.value;
    setFilteredPatients(patients.filter(patient => {
      let firstNameTerm = false;
      let lastNameTerm = false;
      let dateOfBirthTerm = false;
      let phoneNumberTerm = false;
      let mobileNumberTerm = false;
      let emailTerm = false;
      let residenceTerm = false;
      let fileNumberTerm = false;

      if (term.includes('-')) {
        if (term.includes('m-') && term.endsWith('m')) {
          if (patient.months === null) {
            return false;
          }

          let stringBeforeMonths = term.substr(0, term.indexOf('m'));
          let stringAfterMonths = term.substring(term.indexOf('-') + 1, term.lastIndexOf('m'));

          if (isNumeric(stringBeforeMonths) && isNumeric(stringAfterMonths)) {
            let months = patient.months;

            return (months >= parseInt(stringBeforeMonths) &&
                months <= parseInt(stringAfterMonths));
          }
        }

        let stringBeforeDash = term.substr(0, term.indexOf('-'));
        let stringAfterDash = term.substr(term.indexOf('-') + 1);

        if (isNumeric(stringBeforeDash) && isNumeric(stringAfterDash)) {
          let age = patient.age;
          return (age >= parseInt(stringBeforeDash) &&
              age <= parseInt(stringAfterDash));
        }
      }

      if (patient.firstName) {
        firstNameTerm =
            patient.firstName.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.lastName) {
        lastNameTerm =
            patient.lastName.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.dateOfBirth) {
        dateOfBirthTerm =
            patient.dateOfBirth.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.email) {
        emailTerm =
            patient.email.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.phoneNumber) {
        phoneNumberTerm =
            patient.phoneNumber.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.mobileNumber) {
        mobileNumberTerm =
            patient.mobileNumber.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.placeOfResidence) {
        residenceTerm =
            patient.placeOfResidence.toLowerCase().includes(term.toLowerCase());
      }

      if (patient.fileNumber) {
        fileNumberTerm = patient.fileNumber.includes(term);
      }

      return firstNameTerm || lastNameTerm || dateOfBirthTerm
          || emailTerm || phoneNumberTerm || mobileNumberTerm || residenceTerm
          || fileNumberTerm;
    }));
  };

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getIneligiblePatients();
          const patientsData = result.data;

          if (!cancelRequest.current) {
            if (patientsData && Object.keys(patientsData).length > 0) {
              setPatients(patientsData);
              setFilteredPatients(patientsData);
            } else {
              setPatients([]);
              setFilteredPatients([]);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="primary" />
          </div>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{width: '95%'}}>
            <TextField
                label="Search for patient in list"
                id="patient-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <TableContainer style={{width: '95%'}} component={Paper} className={classes.contentWrapper}>
            <Table aria-label="collapsible table" size="small" >
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell><strong>First name(s)</strong></StyledTableCell>
                  <StyledTableCell><strong>Last name</strong></StyledTableCell>
                  <StyledTableCell><strong>Age</strong></StyledTableCell>
                  <StyledTableCell><strong>Circumcision date</strong></StyledTableCell>
                  <StyledTableCell><strong>Days since circumcision</strong></StyledTableCell>
                  <StyledTableCell><strong>Days since last follow-up</strong></StyledTableCell>
                  <StyledTableCell><strong>Actions</strong></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPatients && filteredPatients.map((patient) => (
                    <Row key={patient.id} patient={patient} fetchData={fetchData} removeHandler={removePatient} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(IneligiblePatients);