import React, {useState} from 'react';
import { FormControlLabel, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, Tooltip, IconButton, Checkbox } from "@mui/material";
import ConfirmCircumcisionIcon from "@mui/icons-material/EventAvailable";
import { withStyles } from '@mui/styles';

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function ConfirmCircumcisionDialog(props) {
  const { classes, confirmationHandler, patient } = props;
  const [dateOfCircumcision, setDateOfCircumcision] = useState(undefined);
  const [formattedDateOfCircumcision, setFormattedDateOfCircumcision] =
      useState(undefined);
  const [medicalAid, setMedicalAid] =
      useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    confirmationHandler(patient.id, formattedDateOfCircumcision, medicalAid);
    handleClose();
  };

  const clearFormData = () => {
    setDateOfCircumcision(null);
  };

  const onDateOfCircumcisionChange = (e) => {
    setDateOfCircumcision(e.target.value);
    setFormattedDateOfCircumcision(
        e.target.value.replace('T', ' ').concat(':00.000'));
  };

  const handleMedicalAidChange = (event) => {
    setMedicalAid(event.target.checked);
  };

  return (
      <>
        <Tooltip title="Confirm circumcision">
          <IconButton onClick={handleClickOpen}>
            <ConfirmCircumcisionIcon className={classes.block} color="inherit" />
          </IconButton>
        </Tooltip>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Circumcision Date</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select date of circumcision for {patient.firstName + ' ' + patient.lastName}
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    id="datetime-local"
                    label="Appointment"
                    type="datetime-local"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onDateOfCircumcisionChange}
                />
              </FormControl>
            </div>
            <div style={{paddingTop: '2rem'}}>
              <FormControl className={classes.formControl}>
                <FormControlLabel control={<Checkbox
                    style={{textAlign: 'left'}}
                    checked={medicalAid}
                    onChange={handleMedicalAidChange}

                    color="primary"
                    inputProps={{ 'aria-label': 'medical aid checkbox' }}/>}
                                  label={'Medical Aid'} />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default withStyles(styles)(ConfirmCircumcisionDialog)