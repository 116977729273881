import React, {useState} from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 240,
  },
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function AddFollowUpDialog(props) {
  const { classes, confirmationHandler } = props;
  const [open, setOpen] = useState(false);
  const [date, setDate] = React.useState(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    confirmationHandler(
        date
    );
    handleClose();
  };

  const clearFormData = () => {
    setDate(undefined);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };


  return (
      <>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Add follow-up
        </Button>
        <Dialog fullWidth={true} maxWidth={'sm'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">New follow-up</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the necessary information
            </DialogContentText>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  variant="outlined"
                  id="follow-up-date"
                  label="Date"
                  type="date"
                  value={date}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Abort
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default withStyles(styles)(AddFollowUpDialog)