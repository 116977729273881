import styled from 'styled-components/macro';
import { IconButton } from '@mui/material';

const EditableComponent = styled.div`
  padding: 0;
  display: inline-flex;
  align-items: center;
  position: relative;

  ${props => (props.fullWidth ? 'width: 100%;' : '')}
`;

const ButtonWithIcon = styled(IconButton)`
  padding: ${props => {
  if (props.buttonsize === 'medium') {
    return props.theme.spacing(0.6);
  }
  if (props.buttonsize === 'large') {
    return props.theme.spacing(0.75);
  }
  return props.theme.spacing(0.5);
}}px;
  background-color: ${props => props.theme.palette.background.default};

  box-shadow: ${props => props.theme.shadows[1]};
  &:not(:last-of-type) {
    margin-right: ${props => props.theme.spacing(0.5)}px;
  }

  svg {
    font-size: ${props => {
  if (props.buttonsize === 'medium') {
    return '1.4rem';
  }
  if (props.buttonsize === 'large') {
    return '1.6rem';
  }
  return '1.2rem';
}};
  }
`;

const paddingForInput = props => {
  if (props.size === 'medium') {
    return props.theme.spacing(1, 1.5);
  }
  if (props.size === 'large') {
    return props.theme.spacing(1.5, 2);
  }
  return props.theme.spacing(0.75, 0.75);
};

export { ButtonWithIcon, EditableComponent, paddingForInput };
