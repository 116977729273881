export function isNumeric(str) {
  if (typeof str != "string") return false
  return !isNaN(str) &&
      !isNaN(parseFloat(str))
}

export function padLeadingZeros(num, size) {
  var s = num+"";
  while (s.length < size) s = "0" + s;
  return s;
}