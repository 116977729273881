import React, {useState} from 'react';
import { InputAdornment, IconButton, Typography, Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, Tooltip, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import PrecheckedIcon from "@mui/icons-material/AssignmentInd";
import { withStyles } from '@mui/styles';

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  formControl: {
    paddingLeft: 10,
    paddingTop: 20,
  }
});

function EnterPrecheckDialog(props) {
  const { classes, confirmationHandler, patient } = props;
  const [generallyHealthy, setGenerallyHealthy] = useState(false);
  const [significantIllness, setSignificantIllness] = useState(false);
  const [urethralDischarge, setUrethralDischarge] = useState(false);
  const [takingAspirin, setTakingAspirin] = useState(false);
  const [pastReactionToLocalAnesthetic, setPastReactionToLocalAnesthetic] =
      useState(false);
  const [bleedingDisorder, setBleedingDisorder] =
      useState(false);
  const [bloodPressureTop, setBloodPressureTop] = useState('');
  const [bloodPressureBottom, setBloodPressureBottom] = useState('');
  const [oxygenSaturation, setOxygenSaturation] = useState('');
  const [pulse, setPulse] = useState('');
  const [weight, setWeight] = useState('');
  const [temperature, setTemperature] = useState('');
  const [bloodSugarLevel, setBloodSugarLevel] = useState('');
  const [genitalSore, setGenitalSore] = useState(false);
  const [painOnErection, setPainOnErection] = useState(false);
  const [swellingScrotum, setSwellingScrotum] = useState(false);
  const [painOnUrination, setPainOnUrination] = useState(false);
  const [difficultyRetractingForeskin, setDifficultyRetractingForeskin] = useState(false);
  const [hypertension, setHypertension] = useState(false);
  const [feverChills, setFeverChills] = useState(false);
  const [shortnessOfBreath, setShortnessOfBreath] = useState(false);
  const [abdominalPain, setAbdominalPain] = useState(false);
  const [bruises, setBruises] = useState(false);
  const [rash, setRash] = useState(false);
  const [diabetes, setDiabetes] = useState(false);
  const [medicationAllergies, setMedicationAllergies] = useState(false);
  const [treatedForStd, setTreatedForStd] = useState(false);
  const [abnormalForeskin, setAbnormalForeskin] = useState(false);
  const [hiv, setHiv] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    confirmationHandler(
        patient.id,
        generallyHealthy,
        significantIllness,
        urethralDischarge,
        takingAspirin,
        pastReactionToLocalAnesthetic,
        bleedingDisorder,
        bloodPressureTop,
        bloodPressureBottom,
        weight,
        bloodSugarLevel,
        temperature,
        oxygenSaturation,
        pulse,
        genitalSore,
        painOnErection,
        swellingScrotum,
        painOnUrination,
        difficultyRetractingForeskin,
        hypertension,
        feverChills,
        shortnessOfBreath,
        abdominalPain,
        bruises,
        rash,
        diabetes,
        medicationAllergies,
        treatedForStd,
        abnormalForeskin,
        hiv
    );
    handleClose();
  };

  const clearFormData = () => {
    setGenerallyHealthy(false);
    setSignificantIllness(false);
    setUrethralDischarge(false);
    setTakingAspirin(false);
    setPastReactionToLocalAnesthetic(false);
    setBleedingDisorder(false);
    setBloodPressureTop(false);
    setBloodPressureBottom(false);
    setWeight(false);
    setGenitalSore(false);
    setPainOnErection(false);
    setSwellingScrotum(false);
    setPainOnUrination(false);
    setDifficultyRetractingForeskin(false);
    setHypertension(false);
    setFeverChills(false);
    setShortnessOfBreath(false);
    setAbdominalPain(false);
    setBruises(false);
    setRash(false);
    setDiabetes(false);
    setMedicationAllergies(false);
    setTreatedForStd(false);
    setAbnormalForeskin(false);
    setHiv(false);
  };

  const handleSignificantIllnessChange = (event) => {
    setSignificantIllness(event.target.value === 'true');
  };

  const handleGenerallyHealthyChange = (event) => {
    setGenerallyHealthy(event.target.value === 'true');
  };

  const handleUrethralDischargeChange = (event) => {
    setUrethralDischarge(event.target.value === 'true');
  }

  const handleTakingAspirinChange = (event) => {
    setTakingAspirin(event.target.value === 'true');
  }

  const handlePastReactionToLocalAnestheticChange = (event) => {
    setPastReactionToLocalAnesthetic(event.target.value === 'true');
  }

  const handleBleedingDisorderChange = (event) => {
    setBleedingDisorder(event.target.value === 'true');
  }

  const handleBloodPressureTopChange = (event) => {
    setBloodPressureTop(event.target.value);
  }

  const handleBloodPressureBottomChange = (event) => {
    setBloodPressureBottom(event.target.value);
  }

  const handleWeightChange = (event) => {
    setWeight(event.target.value);
  }

  const handleTemperatureChange = (event) => {
    setTemperature(event.target.value);
  }

  const handleOxygenSaturationChange = (event) => {
    setOxygenSaturation(event.target.value);
  }

  const handlePulseChange = (event) => {
    setPulse(event.target.value);
  }

  const handleBloodSugarLevelChange = (event) => {
    setBloodSugarLevel(event.target.value);
  }

  const handleGenitalSoreChange = (event) => {
    setGenitalSore(event.target.value === 'true');
  }

  const handlePainOnErectionChange = (event) => {
    setPainOnErection(event.target.value === 'true');
  }

  const handleSwellingScrotumChange = (event) => {
    setSwellingScrotum(event.target.value === 'true');
  }

  const handlePainOnUrinationChange = (event) => {
    setPainOnUrination(event.target.value === 'true');
  }

  const handleDifficultyRetractingForeskinChange = (event) => {
    setDifficultyRetractingForeskin(event.target.value === 'true');
  }

  const handleHypertensionChange = (event) => {
    setHypertension(event.target.value === 'true');
  }

  const handleFeverChillsChange = (event) => {
    setFeverChills(event.target.value === 'true');
  }

  const handleShortnessOfBreath = (event) => {
    setShortnessOfBreath(event.target.value === 'true');
  }

  const handleAbdominalPainChange = (event) => {
    setAbdominalPain(event.target.value === 'true');
  }

  const handleBruisesChange = (event) => {
    setBruises(event.target.value === 'true');
  }

  const handleRashChange = (event) => {
    setRash(event.target.value === 'true');
  }

  const handleDiabetesChange = (event) => {
    setDiabetes(event.target.value === 'true');
  }

  const handleMedicationAllergiesChange = (event) => {
    setMedicationAllergies(event.target.value === 'true');
  }

  const handleTreatedForStdChange = (event) => {
    setTreatedForStd(event.target.value === 'true');
  }

  const handleAbnormalForeskinChange = (event) => {
    setAbnormalForeskin(event.target.value === 'true');
  }

  const handleHivChange = (event) => {
    setHiv(event.target.value === 'true');
  }

  return (
      <>
        <Tooltip title="Enter Pre-check data">
          <IconButton onClick={handleClickOpen}>
            <PrecheckedIcon className={classes.block} color="inherit" />
          </IconButton>
        </Tooltip>
        <Dialog fullWidth={true} maxWidth={'md'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Pre-check data</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill in the pre-check information for {patient.firstName + ' ' + patient.lastName}
            </DialogContentText>
            <Typography variant="h5" style={{paddingTop: 10, paddingBottom: 10}}>
              History
            </Typography>
            <div>
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item xs={8}>Generally healthy?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="generallyHealthy" name="generallyHealthy" defaultValue="false" onChange={handleGenerallyHealthyChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Significant illness?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="significantIllness" name="significantIllness" defaultValue="false" onChange={handleSignificantIllnessChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Experiencing urethral discharge?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="urethralDischarge" name="urethralDischarge" defaultValue="false" onChange={handleUrethralDischargeChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Currently taking aspirin?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="takingAspirin" name="takingAspirin" defaultValue="false" onChange={handleTakingAspirinChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Past reaction to local anesthetic?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="pastReactionToLocalAnesthetic" name="pastReactionToLocalAnesthetic" defaultValue="false" onChange={handlePastReactionToLocalAnestheticChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Known bleeding disorder or tends to bleed profusely when cut?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="bleedingDisorder" name="bleedingDisorder" defaultValue="false" onChange={handleBleedingDisorderChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Genital sore (ulcer)?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="genitalSore" name="genitalSore" defaultValue="false" onChange={handleGenitalSoreChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Pain on erection?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="painOnErection" name="painOnErection" defaultValue="false" onChange={handlePainOnErectionChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Swelling of the scrotum?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="swellingScrotum" name="swellingScrotum" defaultValue="false" onChange={handleSwellingScrotumChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Pain on urination?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="painOnUrination" name="painOnUrination" defaultValue="false" onChange={handlePainOnUrinationChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Difficulty in retracting foreskin?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="difficultyRetractingForeskin" name="difficultyRetractingForeskin" defaultValue="false" onChange={handleDifficultyRetractingForeskinChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Hypertension?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="hypertension" name="hypertension" defaultValue="false" onChange={handleHypertensionChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Fever / Chills?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="feverChills" name="feverChills" defaultValue="false" onChange={handleFeverChillsChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Shortness of Breath?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="shortnessOfBreath" name="shortnessOfBreath" defaultValue="false" onChange={handleShortnessOfBreath}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Abdominal pain?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="abdominalPain" name="abdominalPain" defaultValue="false" onChange={handleAbdominalPainChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Open cuts or bruises?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="bruises" name="bruises" defaultValue="false" onChange={handleBruisesChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Rash?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="rash" name="rash" defaultValue="false" onChange={handleRashChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Diabetes?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="diabetes" name="diabetes" defaultValue="false" onChange={handleDiabetesChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Any medication allergies?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="medicationAllergies" name="medicationAllergies" defaultValue="false" onChange={handleMedicationAllergiesChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Treated for any sexually transmitted infections in past month?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="treatedForStd" name="treatedForStd" defaultValue="false" onChange={handleTreatedForStdChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>Abnormalities on foreskin?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="abnormalForeskin" name="abnormalForeskin" defaultValue="false" onChange={handleAbnormalForeskinChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={8}>HIV?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="hiv" name="hiv" defaultValue="false" onChange={handleHivChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Typography>
            </div>
            <Typography variant="h5" style={{paddingTop: 10, paddingBottom: 10}}>
              Vital signs / Weight
            </Typography>
            <FormControl className={classes.formControl}>
              <TextField
                  id="blood-pressure-top"
                  label="Blood pressure "
                  placeholder="Systolic"
                  type="number"
                  className={classes.textField}
                  variant={'outlined'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                  }}
                  value={bloodPressureTop}
                  onChange={handleBloodPressureTopChange}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                  id="blood-pressure-bottom"
                  label=" "
                  placeholder="Diastolic"
                  type="number"
                  className={classes.textField}
                  variant={'outlined'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                  }}
                  value={bloodPressureBottom}
                  onChange={handleBloodPressureBottomChange}
              />
            </FormControl>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    id="weight"
                    label="Weight"
                    type="number"
                    className={classes.textField}
                    variant={'outlined'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    }}
                    value={weight}
                    onChange={handleWeightChange}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    id="blood-sugar-level"
                    label="Blood Sugar Level"
                    type="number"
                    className={classes.textField}
                    variant={'outlined'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">mmol/L</InputAdornment>,
                    }}
                    value={bloodSugarLevel}
                    onChange={handleBloodSugarLevelChange}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    id="temperature"
                    label="Temperature"
                    type="number"
                    className={classes.textField}
                    variant={'outlined'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                    }}
                    value={temperature}
                    onChange={handleTemperatureChange}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    id="oxygen-saturation"
                    label="SpO2"
                    type="number"
                    className={classes.textField}
                    variant={'outlined'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    value={oxygenSaturation}
                    onChange={handleOxygenSaturationChange}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    id="pulse"
                    label="Pulse"
                    type="number"
                    className={classes.textField}
                    variant={'outlined'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">b/min</InputAdornment>,
                    }}
                    value={pulse}
                    onChange={handlePulseChange}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default withStyles(styles)(EnterPrecheckDialog)