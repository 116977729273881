import React, {useState} from 'react';
import { IconButton, Tooltip, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Radio, RadioGroup, FormControlLabel } from "@mui/material";

import { withStyles } from '@mui/styles';
import CircumcisedIcon from "@mui/icons-material/HowToReg";
import ConfirmDeferDialog from "./ConfirmDeferDialog";
import {updatePatient} from "../../utils/api";
import ConfirmIneligibilityDialog from "./ConfirmIneligibilityDialog";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  formControl: {
    paddingLeft: 10,
    paddingTop: 20,
  }
});

function EnterPreccircDialog(props) {
  const { classes, confirmationHandler, patient, fetchData} = props;
  const [epispadius, setEpispadius] = useState(false);
  const [hypospadius, setHypospadius] = useState(false);
  const [tightFrenulum, setTightFrenulum] = useState(false);
  const [phimosis, setPhimosis] = useState(false);
  const [burriedPenis, setBurriedPenis] = useState(false);
  const [webPenis, setWebPenis] = useState(false);
  const [chordee, setChordee] = useState(false);
  const [sti, setSti] = useState(false);
  const [warts, setWarts] = useState(false);
  const [abnormalGenitalDescription, setAbnormalGenitalDescription] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    confirmationHandler(
        patient.id,
        epispadius,
        hypospadius,
        tightFrenulum,
        phimosis,
        burriedPenis,
        webPenis,
        chordee,
        sti,
        warts,
        abnormalGenitalDescription,
    );
    handleClose();
  };

  const clearFormData = () => {
    setAbnormalGenitalDescription('');
  };

  const handleEpispadiusChange = (event) => {
    setEpispadius(event.target.value === 'true');
  };

  const handleHypospadiusChange = (event) => {
    setHypospadius(event.target.value === 'true');
  };

  const handleTightFrenulumChange = (event) => {
    setTightFrenulum(event.target.value === 'true');
  };

  const handlePhimosisChange = (event) => {
    setPhimosis(event.target.value === 'true');
  };

  const handleBurriedPenisChange = (event) => {
    setBurriedPenis(event.target.value === 'true');
  };

  const handleWebPenisChange = (event) => {
    setWebPenis(event.target.value === 'true');
  };

  const handleChordeeChange = (event) => {
    setChordee(event.target.value === 'true');
  };

  const handleStiChange = (event) => {
    setSti(event.target.value === 'true');
  };

  const handleWartsChange = (event) => {
    setWarts(event.target.value === 'true');
  };

  const handleAbnormalGenitalDescriptionChange = (event) => {
    setAbnormalGenitalDescription(event.target.value);
  };

  const updateConfirmationHandler =
      async (type, reason) => {
        await updatePatientDataAndDispatch(patient.id,
            [type === 'defer' ? 'deferred' : 'ineligible',
              type === 'defer' ? 'deferredReason' : 'ineligibleReason',
              'epispadius',
              'hypospadius',
              'tightFrenulum',
              'phimosis',
              'burriedPenis',
              'webPenis',
              'chordee',
              'sti',
              'warts',
              'abnormalGenitalDescription'],
            [true, reason, epispadius,
              hypospadius,
              tightFrenulum,
              phimosis,
              burriedPenis,
              webPenis,
              chordee,
              sti,
              warts,
              abnormalGenitalDescription]);
        fetchData();
      };

  const updatePatientDataAndDispatch = async (patientId, fields, values) => {
    try {

      let updatedFields = {};

      for (let i = 0; i < fields.length; i++) {
        updatedFields[fields[i]] = values[i];
      }

      await updatePatient(
          patientId,
          updatedFields
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
      <>
        <Tooltip title="Enter final examination data">
          <IconButton onClick={handleClickOpen}>
            <CircumcisedIcon className={classes.block} color="inherit" />
          </IconButton>
        </Tooltip>
        <Dialog fullWidth={true} maxWidth={'md'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Genital Examination</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Final pre-circumcision information for {patient.firstName + ' ' + patient.lastName}
            </DialogContentText>
            <div>
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item xs={8}>Epispadius?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="epispadius" name="epispadius" defaultValue="false" onChange={handleEpispadiusChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={8}>Hypospadius?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="hypospadius" name="hypospadius" defaultValue="false" onChange={handleHypospadiusChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={8}>Tight frenulum?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="tightFrenulum" name="tightFrenulum" defaultValue="false" onChange={handleTightFrenulumChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={8}>Phimosis?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="phimosis" name="phimosis" defaultValue="false" onChange={handlePhimosisChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={8}>Burried penis?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="burriedPenis" name="burriedPenis" defaultValue="false" onChange={handleBurriedPenisChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={8}>Web penis?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="webPenis" name="webPenis" defaultValue="false" onChange={handleWebPenisChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={8}>Chordee?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="chordee" name="chordee" defaultValue="false" onChange={handleChordeeChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={8}>STI?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="sti" name="sti" defaultValue="false" onChange={handleStiChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={8}>Web penis?</Grid>
                  <Grid item xs={4}>
                    <RadioGroup row aria-label="warts" name="warts" defaultValue="false" onChange={handleWartsChange}>
                      <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                      />
                      <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={4}> </Grid>
                  <Grid item xs={8}>
                    <TextField
                        id="abnormal-genital-description"
                        label="Any other abnormalities?"
                        placeholder="Description"
                        type="text"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth={true}
                        value={abnormalGenitalDescription}
                        onChange={handleAbnormalGenitalDescriptionChange}
                    />
                  </Grid>
                </Grid>
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <ConfirmDeferDialog confirmationHandler={updateConfirmationHandler} closeParentDialog={handleClose} />
            <ConfirmIneligibilityDialog confirmationHandler={updateConfirmationHandler} closeParentDialog={handleClose} />
            <Button onClick={handleConfirm} color="primary">
              Commence circumcision
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default withStyles(styles)(EnterPreccircDialog)