import React, {useState} from 'react';
import JsPdf from 'jspdf';
import { Fab, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, MenuItem, Select, InputLabel } from "@mui/material";

import { withStyles } from '@mui/styles';
import PrintIcon from '@mui/icons-material/Print';
import {formatTime} from "../../utils/timeHelper";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {

  }
});

function PrintFollowUpListDialog(props) {
  const { classes, patients } = props;
  const [date, setDate] = useState(undefined);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const generateData = (agedAtLeast, youngerThan) => {
    let result = [];
    patients.forEach(patient => {
      if (patient.followUpChecks.length > 0) {
        let mostRecentFollowUp =
            patient.followUpChecks.sort(function(a,b){return new Date(b.date) - new Date(a.date);})[0]

        if (isInDateRange(date, mostRecentFollowUp.date) &&
            patient.age !== null &&
            patient.age >= agedAtLeast &&
            patient.age < youngerThan
          ) {
          let data = {
            NO: '  ',
            'FIRST NAME': patient.firstName ? patient.firstName : ' ',
            SURNAME: patient.lastName ? patient.lastName : ' ',
            DOB: patient.dateOfBirth ? formatTime(patient.dateOfBirth, 'dd.LL.yyyy') : ' ',
            AGE: patient.age !== null ? patient.age.toString() + ((patient.months !== null && patient.age < 1) ? ' (' + patient.months + 'm)' : '') : ' ',
            'PHONE': patient.phoneNumber !== null ? patient.phoneNumber : ' ',
            'DoP': patient.circumcisionDate !== null ? formatTime(patient.circumcisionDate, 'dd.LL.yyyy') : ' ',
            'DsP': Math.floor(( new Date(date).valueOf() - new Date(patient.circumcisionDate.substr(0, patient.circumcisionDate.indexOf(' '))).valueOf() ) / 86400000).toString(),
            NOTES: '                                       \n      ',
            'DOCTOR': '     '
          };
          result.push(Object.assign({}, data));
        }
      }
    });
    return result;
  };

  const isInDateRange = (selectedDate, folowUpDate) => {
    let exactDate = new Date(selectedDate);
    let dayBefore = new Date(selectedDate);
    dayBefore.setDate(exactDate.getDate() - 1);

    return (exactDate.valueOf() === new Date(folowUpDate.substr(0, folowUpDate.indexOf(' '))).valueOf()) ||
        (dayBefore.valueOf() === new Date(folowUpDate.substr(0, folowUpDate.indexOf(' '))).valueOf())
  }

  const createHeaders = (keys, widths) => {
    let result = [];
    for (let i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: widths[i],
        align: "center",
        padding: 0
      });
    }
    return result;
  }

  const downloadList = () => {
    var headers = createHeaders([
      'NO',
      'FIRST NAME',
      'SURNAME',
      'DOB',
      'AGE',
      'PHONE',
      'DoP',
      'DsP',
      'NOTES',
      'DOCTOR',
    ], [
      15, 50, 50, 40, 20, 35, 30, 25, 90, 30
    ]);

    var doc = new JsPdf({ putOnlyUsedFonts: true, orientation: 'landscape' });

    let infants = generateData(0, 1);
    let kids = generateData(1, 11);
    let adolescents = generateData(11, 19);
    let adults = generateData(19, 150);

    if (infants.length > 0) {
      doc.setFont("helvetica", "bold");
      doc.text(`Follow-Up Patient List ${formatTime(date, 'dd.LL.yyyy')} (Infants)`, 1, 10);
      doc.table(1, 15, infants, headers, { autoSize: false, fontSize: 9 });
    }
    if (kids.length > 0) {
      if (infants.length > 0) doc.addPage('a4', 'landscape');
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Follow-Up Patient List ${formatTime(date, 'dd.LL.yyyy')} (Kids)`, 1, 10);
      doc.table(1, 15, kids, headers, { autoSize: false, fontSize: 9 });
    }

    if (adolescents.length > 0) {
      if (infants.length > 0 || kids.length > 0) doc.addPage('a4', 'landscape');
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Follow-Up Patient List ${formatTime(date, 'dd.LL.yyyy')} (Adolescents)`, 1, 10);
      doc.table(1, 15, adolescents, headers, { autoSize: false, fontSize: 9 });
    }

    if (adults.length > 0) {
      if (infants.length > 0 || kids.length > 0 || adolescents.length > 0) doc.addPage('a4', 'landscape');
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`Follow-Up Patient List ${formatTime(date, 'yyyy-LL-dd')} (Adults)`, 1, 10);
      doc.table(1, 15, adults, headers, { autoSize: false, fontSize: 9 });
    }

    doc.save(`follow-up-list-${formatTime(date, 'yyyy-LL-dd')}.pdf`)

  };

  const clearFormData = () => {
    setDate(undefined);
  };

  const onDateChange = (e) => {
    setDate(e.target.value);
  };

  return (
      <div>
        <Fab
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed'}}
        >
          <PrintIcon/>
        </Fab>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Print upcoming circumcision list</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select the date to print the lists for
            </DialogContentText>
            <br/>

            <FormControl className={classes.formControl}>
              <TextField
                  id="date"
                  label="Date"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={date}
                  onChange={onDateChange}
              />
            </FormControl>


          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={downloadList} color="primary">
              Download
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(PrintFollowUpListDialog)