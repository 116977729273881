import React from 'react';
import './App.css';
import UserProvider from "./components/auth/UserProvider";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import Paperbase from "./components/Paperbase";

class App extends React.Component {
  render() {
    return (
        <div className="App">
          <ThemeConfig>
              <UserProvider>
                <GlobalStyles />
                <Paperbase />
              </UserProvider>
          </ThemeConfig>
        </div>
    );
  }
}

export default App;
