import axios from 'axios';
import { auth } from "../components/auth/firebase";

let token = null;

async function setupAuthorizationHeaders() {
  token = await auth.currentUser.getIdToken();

  return {
    Authorization: `Bearer ${token}`,
  };
}

const api = axios.create({
  baseURL: process.env.REACT_APP_UNICIRC_BACKEND,
  headers: {
    accept: 'application/json',
  },
});

api.interceptors.request.use(
    async config => {
      const newConfig = config;
      const newHeaders = await setupAuthorizationHeaders();
      newConfig.headers = {
        ...config.headers,
        ...newHeaders,
      };
      return newConfig;
    },
    error => {
      Promise.reject(error);
    }
);

export default api;

/* --- GET requests --- */

export async function getUsers() {
  return api.get('/users');
}

export async function getPractices(page, size) {
  return api.get(`/practices?page=${page}&size=${size}`);
}

export async function searchPractices(searchTerm) {
  return api.get(`/practices-search?searchTerm=${searchTerm}`);
}

export async function getPatients(circumcisionConfirmed, precheckDone, circumcisionDone) {
  return api.get(`/patients?circumcisionConfirmed=${circumcisionConfirmed}&precheckDone=${precheckDone}&circumcisionDone=${circumcisionDone}`);
}

export async function getDischargedPatients() {
  return api.get(`/patients?discharged=true`);
}

export async function getAllCompletedPatients(fromAge = 0, toAge = 150, fromCircDate = '2000-01-01', toCircDate = '2100-01-01') {
  return api.get(`/patients?allCompleted=true&fromAge=${fromAge}&toAge=${toAge}&fromCircDate=${fromCircDate}&toCircDate=${toCircDate}`);
}

export async function getUninterestedPatients() {
  return api.get(`/patients?uninterested=true`);
}

export async function getDeferredPatients() {
  return api.get(`/patients?deferred=true`);
}

export async function getIneligiblePatients() {
  return api.get(`/patients?ineligible=true`);
}

export async function getTrainees() {
  return api.get('/trainees');
}

export async function getPlacesOfResidence() {
  return api.get('/places-of-residence');
}

export async function getReasonsForCircumcision() {
  return api.get('/reasons-for-circumcision');
}

/* --- POST requests ---*/

export async function createUser(body) {
  return api.post(`/users`, body);
}

export async function createPatient(body) {
  return api.post(`/patients`, body);
}

export async function addFollowUpToPatient(patientId, body) {
  return api.post(`/patients/${patientId}/follow-ups`, body);
}

export async function addAdverseEventToPatient(patientId, body) {
  return api.post(`/patients/${patientId}/adverse-events`, body);
}

/* --- PUT requests ---*/

export async function updatePatient(patientId, body) {
  return api.put(`/patients/${patientId}`, body);
}

export async function updateCircumcision(patientId, body) {
  return api.put(`/patients/${patientId}/circumcision`, body);
}

export async function updateFollowUpCheck(patientId, followUpCheckId, body) {
  return api.put(`/patients/${patientId}/follow-ups/${followUpCheckId}`, body);
}

export async function updateAdverseEvent(patientId, adverseEventId, body) {
  return api.put(`/patients/${patientId}/adverse-events/${adverseEventId}`, body);
}

export async function getStatistics() {
  return api.get('/statistics');
}

/* --- DELETE requests ---*/

export async function deletePatient(patientId) {
  return api.delete(`/patients/${patientId}`);
}