import React, {useEffect, useRef, useState} from 'react';
import {getStatistics} from "../../utils/api";
import ReactApexChart from 'react-apexcharts';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import zaIcon from '@iconify-icons/cif/za';
import szIcon from '@iconify-icons/cif/sz';
import bwIcon from '@iconify-icons/cif/bw';
import etIcon from '@iconify-icons/cif/et';
import zwIcon from '@iconify-icons/cif/zw';
import mzIcon from '@iconify-icons/cif/mz';
import lsIcon from '@iconify-icons/cif/ls';
import keIcon from '@iconify-icons/cif/ke';
import naIcon from "@iconify-icons/cif/na";
import mwIcon from "@iconify-icons/cif/mw";
import tzIcon from "@iconify-icons/cif/tz";
import rwIcon from "@iconify-icons/cif/rw";
import ugIcon from "@iconify-icons/cif/ug";
import zmIcon from "@iconify-icons/cif/zm";

import {useTheme, withStyles} from '@mui/styles';
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  CircularProgress,
  TableCell,
  TableContainer,
  Card
} from "@mui/material";
import {formatTime} from "../../utils/timeHelper";
import {Icon} from "@iconify/react";
import {BaseOptionChart} from "../charts";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10,
    height: '100%',
  },
  contentWrapper: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
    marginRight: '2rem',
  },
});


function Dashboard(props) {
  const theme = useTheme();
  const { classes } = props;

  const [countryValue, setCountryValue] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [statistics, setStatistics] = useState('');

  const handleCountrySwitch = (event, newValue) => {
    setCountryValue(newValue);
  };

  const arrSumReasons = arr => arr.filter(v => v.count !== undefined).reduce((a, b) => a + b.count, 0);

  const cancelRequest = useRef();

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getStatistics();
          const statisticsData = result.data;

          if (!cancelRequest.current) {
            if (statisticsData && Object.keys(statisticsData).length > 0) {
              setStatistics(statisticsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            Access denied!
          </>
      );
    }

    if (isLoading || !statistics) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    const colors = [
      '#003f5c',
      '#2f4b7c',
      '#665191',
      '#a05195',
      '#d45087',
      '#f95d6a',
      '#ff7c43',
      '#ffa600'
    ]

    const CHART_DATA = [];
    const CHART_LABELS = [];

    if (statistics.deviceUsage) {
      statistics.deviceUsage.map((usage) => {
        CHART_DATA.push(usage.numberOfUsages);
        CHART_LABELS.push(`${usage.deviceType}`);
      })
    }

    const chartOptions = { ...BaseOptionChart(), ...{
      colors: colors,
      labels: CHART_LABELS,
      stroke: { colors: [theme.palette.background.paper] },
      legend: { show: false, floating: true, horizontalAlign: 'center' },
      dataLabels: {
        enabled: true,
        dropShadow: { enabled: false },
        formatter: (val, { seriesIndex, _, w }) => {
          return `${w.config.labels[seriesIndex]} (${(Math.round((val + Number.EPSILON) * 100) / 100).toFixed(0)}%)`
        },
      },
      tooltip: {
        fillSeriesColor: false,
        y: {
          formatter: (seriesName) => `${seriesName}`,
          title: {
            formatter: (seriesName) => `${seriesName}`
          }
        }
      },
      plotOptions: {
        pie: { donut: { labels: { show: false } } }
      }
    }};

    const countries = [
        'South Africa', 'Eswatini', 'Botswana', 'Lesotho', 'Zimbabwe',
      'Mozambique', 'Ehtiopia', 'Kenya', 'Malawi', 'Namibia', 'Tanzania',
        'Rwanda', 'Uganda', 'Zambia'
    ];

    const provinces = [
      { province: 'Gauteng', country: 'South Africa'},
      { province: 'KwaZulu-Natal', country: 'South Africa'},
      { province: 'Lubombo', country: 'Eswatini'},
      { province: 'Eastern Cape', country: 'South Africa'},
      // { province: 'Mpumalanga', country: 'South Africa'},
      // { province: 'Free State', country: 'South Africa'},
      // { province: 'Limpopo', country: 'South Africa'},
      // { province: 'North West', country: 'South Africa'},
      // { province: 'Northern Cape', country: 'South Africa'},
      { province: 'Hhohho', country: 'Eswatini'},
      // { province: 'Manzini', country: 'Eswatini'},
      // { province: 'Shiselweni', country: 'Eswatini'},

    ]

    let amountReasons = arrSumReasons(statistics.reasons);

    return (
        <div className={classes.contentWrapper} style={{ marginBottom: '3rem' }} >
          <Grid container spacing={3}>
            <Grid item xl={4} md={4} xs={12}>
              <Card className={classes.paper} sx={{ height: '280px' }}>
                <Typography variant="h1" color="textPrimary">
                  {countryValue === 0 ? statistics.circumcisionsCompleted : 0}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  Circumcised
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  in {countries[countryValue]}
                </Typography>
                <Tabs
                    sx={{ marginTop: '5rem' }}
                    value={countryValue}
                    onChange={handleCountrySwitch}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="circumcision-priority-countries-select"
                >
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={zaIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={szIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={bwIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={lsIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={zwIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={mzIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={etIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={keIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={mwIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={naIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={tzIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={rwIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={ugIcon} width={32} height={32} />} />
                  <Tab sx={{ width: 40, minWidth: 40, margin: 0, padding: 0 }} icon={<Icon icon={zmIcon} width={32} height={32} />} />
                </Tabs>
              </Card>
            </Grid>
            <Grid item xl={4} md={4} xs={12}>
              <Card title="Next appointment date" className={classes.paper} sx={{ height: '280px' }}>
                { statistics.numberOfAppointmentsNextDate && statistics.nextAppointmentDate &&
                  <>
                    <Typography variant="h2" color="textPrimary">
                      {formatTime(statistics.nextAppointmentDate, 'dd.LL.yyyy')}
                    </Typography>
                    <Typography variant="subtitle1" color="textPrimary">
                      Next circumcision date
                    </Typography>
                    <Typography variant="h4" color="textPrimary">
                      {statistics.numberOfAppointmentsNextDate} {statistics.numberOfAppointmentsNextDate > 1 ? 'patients' : 'patient'}
                    </Typography>
                    <Typography variant="subtitle2" color="textPrimary">
                      Number of patients
                    </Typography>
                  </>
                }
                { !statistics.numberOfAppointmentsNextDate && !statistics.nextAppointmentDate &&
                  <>
                    <Typography variant="subtitle1" color="textPrimary">
                      Next circumcision date
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      No upcoming circumcisions
                    </Typography>
                  </>
                }
              </Card>
            </Grid>
            <Grid item xl={4} md={4} xs={12}>
              <Card className={classes.paper} sx={{ height: '280px' }}>
                <ReactApexChart type="pie" series={CHART_DATA} options={chartOptions} height={280} />
              </Card>
            </Grid>
            <Grid item xl={4} md={12} xs={12}>
              <Card title="Demographics" className={classes.paper}>
                <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
                  Demographics
                </Typography>
                <TableContainer>
                  <Table aria-label="collapsible table" size="small" >
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell style={{ width: 20 }}/>
                        <TableCell>Place of residence</TableCell>
                        <TableCell>Number of patients</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { statistics.demographics && statistics.demographics.slice(0, 8).map((demographic) => (
                          <TableRow key={demographic.placeOfResidence}>
                            <TableCell>
                              <Icon style={{ marginTop: 6 }} icon={zaIcon} width={24} height={24} />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {`${demographic.placeOfResidence}, ${demographic.countryOfResidence}`}
                            </TableCell>
                            <TableCell>{demographic.numberOfResidents}</TableCell>
                          </TableRow>
                      ))}
                      { provinces.map((province) => (
                          <TableRow key={province.province}>
                            <TableCell>
                              <Icon style={{ marginTop: 6 }} icon={province.country === 'South Africa' ? zaIcon : szIcon} width={24} height={24} />
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ flexDirection: 'row' }}>
                              {`${province.province}, ${province.country}`}
                            </TableCell>
                            <TableCell>0</TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item xl={4} md={6} xs={12}>
              <Card title="Device usage" className={classes.paper}>
                <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
                  Device usages
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  Infants (0-1)
                </Typography>
                <TableContainer>
                  <Table aria-label="collapsible table" size="small" >
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell>Device size</TableCell>
                        <TableCell>Devices used</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { statistics.deviceUsageInfants && statistics.deviceUsageInfants.map((usage) => (
                          <TableRow key={usage.deviceType}>
                            <TableCell component="th" scope="row">{usage.deviceType}</TableCell>
                            <TableCell>{usage.numberOfUsages}</TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="subtitle1" color="textPrimary" style={{paddingTop: '1rem'}}>
                  Children (1-10)
                </Typography>
                <TableContainer>
                  <Table aria-label="collapsible table" size="small" >
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell>Device size</TableCell>
                        <TableCell>Devices used</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { statistics.deviceUsageKids && statistics.deviceUsageKids.map((usage) => (
                          <TableRow key={usage.deviceType}>
                            <TableCell component="th" scope="row">{usage.deviceType}</TableCell>
                            <TableCell>{usage.numberOfUsages}</TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="subtitle1" color="textPrimary" style={{paddingTop: '1rem'}}>
                  Adolescents (10-19)
                </Typography>
                <TableContainer>
                  <Table aria-label="collapsible table" size="small" >
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell>Device size</TableCell>
                        <TableCell>Devices used</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { statistics.deviceUsageAdolescents && statistics.deviceUsageAdolescents.map((usage) => (
                          <TableRow key={usage.deviceType}>
                            <TableCell component="th" scope="row">{usage.deviceType}</TableCell>
                            <TableCell>{usage.numberOfUsages}</TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="subtitle1" color="textPrimary" style={{paddingTop: '1rem'}}>
                  Adults (20+)
                </Typography>
                <TableContainer>
                  <Table aria-label="collapsible table" size="small" >
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell>Device size</TableCell>
                        <TableCell>Devices used</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { statistics.deviceUsageAdults && statistics.deviceUsageAdults.map((usage) => (
                          <TableRow key={usage.deviceType}>
                            <TableCell component="th" scope="row">{usage.deviceType}</TableCell>
                            <TableCell>{usage.numberOfUsages}</TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item xl={4} md={6} xs={12}>
              <Card title="Demographics" className={classes.paper}>
                <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
                  Adverse events
                </Typography>
                <TableContainer>
                  <Table aria-label="collapsible table" size="small" >
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell>Severity</TableCell>
                        <TableCell/>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { statistics.adverseEvents &&
                        <>
                          <TableRow key={1}>
                            <TableCell>Mild</TableCell>
                            <TableCell>{statistics.adverseEvents.nrMild}</TableCell>
                          </TableRow>
                          <TableRow key={2}>
                            <TableCell>Moderate</TableCell>
                            <TableCell>{statistics.adverseEvents.nrModerate}</TableCell>
                          </TableRow>
                          <TableRow key={3}>
                            <TableCell>Severe</TableCell>
                            <TableCell>{statistics.adverseEvents.nrSevere}</TableCell>
                          </TableRow>
                        </>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item xl={4} md={6} xs={12}>
              <Card title="Demographics" className={classes.paper}>
                <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
                  Reasons for circumcision
                </Typography>
                <TableContainer>
                  <Table aria-label="collapsible table" size="small" >
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell>Reason</TableCell>
                        <TableCell/>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { statistics.reasons && statistics.reasons.slice(0, 8).map((reason) => (
                          <TableRow key={reason.reason}>
                            <TableCell>
                              {`${reason.reason}`}
                            </TableCell>
                            <TableCell>{`${(reason.count / amountReasons * 100).toFixed(0)}%`}</TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item xl={4} md={6} xs={12}>
              <Card title="Age distribution" className={classes.paper}>
                <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
                  Age distribution
                </Typography>
                <TableContainer>
                  <Table aria-label="collapsible table" size="small" >
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell>Group</TableCell>
                        <TableCell/>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { statistics.ageDistribution &&
                          <>
                            <TableRow key={statistics.ageDistribution.nrInfants}>
                              <TableCell>Infants</TableCell>
                              <TableCell>{`${(statistics.ageDistribution.nrInfants / statistics.circumcisionsCompleted * 100).toFixed(0)}%`}</TableCell>
                            </TableRow>
                            <TableRow key={statistics.ageDistribution.nrKids}>
                              <TableCell>Kids</TableCell>
                              <TableCell>{`${(statistics.ageDistribution.nrKids / statistics.circumcisionsCompleted * 100).toFixed(0)}%`}</TableCell>
                            </TableRow>
                            <TableRow key={statistics.ageDistribution.nrAdolescents}>
                              <TableCell>Adolescents</TableCell>
                              <TableCell>{`${(statistics.ageDistribution.nrAdolescents / statistics.circumcisionsCompleted * 100).toFixed(0)}%`}</TableCell>
                            </TableRow>
                            <TableRow key={statistics.ageDistribution.nrAdults}>
                              <TableCell>Adults</TableCell>
                              <TableCell>{`${(statistics.ageDistribution.nrAdults / statistics.circumcisionsCompleted * 100).toFixed(0)}%`}</TableCell>
                            </TableRow>
                          </>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        </div>
    );
  };

  return renderData();
}

export default withStyles(styles)(Dashboard);