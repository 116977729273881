import { DateTime } from 'luxon';
import i18next from 'i18next';

const timeZone = 'Europe/Berlin';
const defaultFormat = 'dd.LL.yyyy, HH:mm:ss';

export function formatTime(date, formatStr = defaultFormat) {
  if (!date) {
    return null;
  }
  const sqlDate = DateTime.fromSQL(date, { zone: 'utc' });

  return sqlDate
  .setZone(timeZone)
  .setLocale(i18next.language)
  .toFormat(formatStr);
}

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  if (age < 0) return 0;
  return age;
}