import React, {useState} from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { withStyles } from '@mui/styles';

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function ConfirmIneligibilityDialog(props) {
  const { classes, confirmationHandler, closeParentDialog } = props;
  const [ineligibleReason, setIneligibleReason] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
    closeParentDialog();
  };

  const handleConfirm = () => {
    confirmationHandler('ineligible', ineligibleReason);
    handleClose();
  };

  const clearFormData = () => {
    setIneligibleReason('');
  };

  const handleDeferredReasonChange = (event) => {
    setIneligibleReason(event.target.value);
  };

  return (
      <>
        <Button onClick={handleClickOpen} color="primary">
          Patient ineligible
        </Button>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Defer patient</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please specify the reason for deferring the patient
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    id="deferred-reason"
                    label="Reason"
                    type="text"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth={true}
                    onChange={handleDeferredReasonChange}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default withStyles(styles)(ConfirmIneligibilityDialog)