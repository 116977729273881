import styled from 'styled-components';

export const DescriptionList = styled.dl`
  margin: 0;
`;

export const DescriptionTerm = styled.dt`
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-bottom: ${props => props.theme.spacing(0.5)}px;
`;

export const Description = styled.dd`
  margin: 0;
`;
