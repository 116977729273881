import React from 'react';
import { Chip, Avatar } from '@mui/material';
import { withStyles } from '@mui/styles';


const styles = () => ({
  block: {
    display: 'block',
  },
});

function UserRoleChip(props) {
  const { role } = props;

  const formatRole = (rawType) => {
    console.log(rawType);
    if (!rawType) {
      return null;
    }

    switch (rawType) {
      case 'SUPER':
        return 'Super Admin';
      case 'STANDARD':
        return 'Standard User';
      default:
        return '';
    }
  }

  const formatShortRole = (rawType) => {
    if (!rawType) {
      return null;
    }

    switch (rawType) {
      case 'SUPER':
        return 'SA';
      case 'STANDARD':
        return 'STD';
      default:
        return '';
    }
  }

  let roleString = formatRole(role);

  return (
      <Chip avatar={<Avatar>{formatShortRole(role)}</Avatar>} label={roleString} />
  );
}

export default withStyles(styles)(UserRoleChip)