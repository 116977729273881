import React, {useState} from 'react';
import { IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, Tooltip } from "@mui/material";
import { withStyles } from '@mui/styles';

import UninterestedIcon from "@mui/icons-material/NotInterested";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function ConfirmUniterestedDialog(props) {
  const { classes, confirmationHandler, patient } = props;
  const [uninterestedReason, setUninterestedReason] = useState(undefined);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    confirmationHandler(patient.id, uninterestedReason);
    handleClose();
  };

  const clearFormData = () => {
    setUninterestedReason('');
  };

  const onReasonChange = (e) => {
    setUninterestedReason(e.target.value);
  };

  return (
      <>
        <Tooltip title="Set patient as no show">
          <IconButton onClick={handleClickOpen}>
            <UninterestedIcon className={classes.block} color="inherit" />
          </IconButton>
        </Tooltip>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Patient no longer interested?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Fill in the reason why patient should be archived
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    id="uninterested-reason"
                    label="Reason"
                    type="text"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth={true}
                    onChange={onReasonChange}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default withStyles(styles)(ConfirmUniterestedDialog)