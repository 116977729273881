import React, {useState} from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, MenuItem, Select, InputLabel } from "@mui/material";

import { withStyles } from '@mui/styles';

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function ConfirmDeferDialog(props) {
  const { classes, confirmationHandler, closeParentDialog } = props;
  const [deferredReason, setDeferredReason] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
    closeParentDialog();
  };

  const handleConfirm = () => {
    confirmationHandler('defer', deferredReason);
    handleClose();
  };

  const clearFormData = () => {
    setDeferredReason('');
  };

  const handleIneligibleReasonChange = (event) => {
    setDeferredReason(event.target.value);
  };

  return (
      <>
        <Button onClick={handleClickOpen} color="primary">
          Defer patient
        </Button>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Ineligible patient</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Why is the patient ineligible?
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    id="ineligible-reason"
                    label="Reason"
                    type="text"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth={true}
                    onChange={handleIneligibleReasonChange}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default withStyles(styles)(ConfirmDeferDialog)